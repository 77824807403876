import {
    React,Component,BootstrapTable,
    paginationFactory,Button, ButtonToolbar, Card, 
    Form, Col, Row, axios, Container, Banner, Typeahead, TimePicker,
    cellEditFactory, Type, moment, NavLink, Alert, AppConfiguration, NumberFormat,
    NoserLoading, Noser, sizePerPageRenderer, DatePicker, Tabs, Tab, Accordion
} 
from '../../noser-hris-component';

class ManageEmployee extends Component {
    constructor(props) {
        super(props);
        //this.refAssurance = React.createRef();
        this.state = {
            userinfo        :   [],
            isloading       :   false,
            isshow          :   false,
            alerttype       :   "",
            message         :   "",
            color           :   "",
            fade            :   true,
            disablecard     :   true,
            disableresigned :   true,
            disablebutton   :   true,
            
            clientId        :   "",
            client          :   "",
            employeeId      :   "",
            profileId       :   "",
            employeeName    :   "",
            employeeNo      :   "",
            statusId        :   "",
            
            clientDDL       :   [],
            employeeDDL     :   [],
            employeeList    :   [],

            /*PROFILE*/
            profileStatus   :   "",
            nickName        :   "",
            firstName       :   "",
            middleName      :   "",
            lastName        :   "",
            homeNumber      :   "",
            officeNumber    :   "",
            mobileNumber    :   "",
            emailAddress    :   "",
            facebook        :   "",
            twitter         :   "",
            dateofBirth     :   "",
            placeofBirth    :   "",
            height          :   "",
            weight          :   "",
            languageSpoken  :   "",
            hobbies         :   "",
            contactName     :   "",
            contactNumber   :   "",
            contactRelation :   "",
            sssNumber       :   "",
            tinNumber       :   "",
            phicNumber      :   "",
            hdmfNumber      :   "",
            
            arrangementId   :   "",
            arrangement     :   "",
            bloodtypeId     :   "",
            bloodtype       :   "",
            nationalityId   :   "",
            nationality     :   "",
            religionId      :   "",
            religion        :   "",
            genderId        :   "",
            gender          :   "",
            civilstatusId   :   "",
            civilstatus     :   "",

            arrangementDDL  :   [],
            bloodtypeDDL    :   [],
            nationalityDDL  :   [],
            religionDDL     :   [],
            genderDDL       :   [
                {"id":"1","name":"MALE"},
                {"id":"2","name":"FEMALE"}
            ],
            civilstatusDDL   :   [
                {"id":"1","name":"SINGLE"},
                {"id":"2","name":"MARRIED"},
                {"id":"3","name":"DIVORCED"},
                {"id":"4","name":"SEPARATED"}
            ],
            
            /*201*/
            locationId      :   "",
            positionId      :   "",
            employmentId    :   "",
            cardtypeId      :   "",
            paytypeId       :   "",
            paymodeId       :   "",
            periodtypeId    :   "",
            scheduleId      :   "",
            approver1Id     :   "",
            approver2Id     :   "",
            salaryRate      :   "",
            seaRate         :   "",
            ecolaRate       :   "",
            colaRate        :   "",
            isdefaultLocation:  "",
            cardNumber      :   "",
            dateHired       :   "",
            dateResigned    :   "",
            contractStart   :   "",
            contractEnd     :   "",

            location        :   "",
            position        :   "",
            employment      :   "",
            cardtype        :   "",
            paytype         :   "",
            paymode         :   "",
            periodtype      :   "",
            schedule        :   "",
            approver1       :   "",
            approver2       :   "",
            remarks         :   "",

            suffixName      :   "",
            sssNumberRemarks       :   "",
            tinNumberRemarks       :   "",
            phicNumberRemarks      :   "",
            hdmfNumberRemarks      :   "",

            requestDate            :    "",
            pullOutDate            :    "",
            returnDate             :    "",

            locationDDL    :   [],
            positionDDL    :   [],
            employmentDDL  :   [],
            cardtypeDDL    :   [],
            paytypeDDL     :   [],
            paymodeDDL     :   [],
            scheduleDDL    :   [],
            periodtypeDDL  :   [],
            periodtypeList :   [],
            approver1DDL   :   [],
            approver2DDL   :   [],

            employmentGRID :   [],
            backgroundGRID :   [],
            addressGRID    :   [],
            educationGRID  :   [],

            backgroundGRIDDel :   [],
            addressGRIDDel    :   [],
            educationGRIDDel  :   [],

            addrtypeDDL    :   [
                {"value":"0","label":"CURRENT ADDRESS"},
                {"value":"1","label":"REGISTERED ADDRESS"},
                {"value":"2","label":"PROVINCIAL ADDRESS"},
                {"value":"3","label":"CORRESPONDENCE ADDRESS"},
                {"value":"4","label":"FOREIGN ADDRESS"}
            ],
            roleDDL         :   [
                {"value":"0","label":"FATHER"},
                {"value":"1","label":"MOTHER"},
                {"value":"2","label":"SPOUSE"},
                {"value":"3","label":"BROTHER"},
                {"value":"4","label":"SISTER"},
                {"value":"5","label":"CHILD"},
            ],
            levelDDL         :   [
                {"value":"0","label":"COLLEGE"},
                {"value":"1","label":"HIGH SCHOOL"},
                {"value":"5","label":"SENIOR HIGH SCHOOL"},
                {"value":"4","label":"JUNIOR HIGH SCHOOL"},
                {"value":"2","label":"ELEMENTARY"},
                {"value":"3","label":"OTHERS"},
            ],
            regionDDL      :   [],
            provinceDDL    :   [],
            cityDDL        :   [],

            mobileNumberEffectivityDate:"",
            civilStatusEffectivityDate:"",
            locationEffectivityDate:"",
            positionEffectivityDate:"",
            employmentStatusEffectivityDate:"",
            dateResignedEffectivityDate:"",
            contractDateEndEffectivityDate:"",
            payCardTypeEffectivityDate:"",
            payCardNumberEffectivityDate:"",
            payTypeEffectivityDate:"",
            payModeEffectivityDate:"",
            workScheduleEffectivityDate:"",
            periodTypeEffectivityDate:"",
            salaryRateEffectivityDate:"",
            seaRateEffectivityDate:"",
            eColaRateEffectivityDate:"",
            colaRateEffectivityDate:"",
            approver1EffectivityDate:"",
            approver2EffectivityDate:"",


            //effectivityMinDate: new Date(),
            employeedetails:[],
            
            hmoId:"0",
            cabinetId:"",
            drawerId:"",
            hmo:"",
            hmoDate:"",
            cabinet:"",
            drawer:"",
            hmoDDL:[
                {
                    "id":"0",
                    "name":"W/O"
                },
                {
                    "id":"1",
                    "name":"WITH"
                },
                {
                    "id":"2",
                    "name":"WAIVER"
                },
                {
                    "id":"3",
                    "name":"DEACTIVATE"
                }
            ],
            cardNumberHmo:"",
            policyNumber:"",
            assuranceDDL:[
                {
                    "id":"1",
                    "name":"GENERALI"
                },
                {
                    "id":"2",
                    "name":"MAXICARE"
                },
                {
                    "id":"3",
                    "name":"PHILCARE"
                },
            ],
            assurance:"",
            assuranceId:"",
            isWithAtd:"",
            disableWithHmmo:true,
            suffixDDL: [
                {name: 'Select Suffix Name', value: "",},
                {name: 'SR',               value: 'SR',},
                {name: 'JR',               value: 'JR',},
                {name: 'I',              value: 'I',},
                {name: 'II',              value: 'II',},
                {name: 'III',              value: 'III',},
                {name: 'IV',              value: 'IV',},
                {name: 'V',              value: 'V',},
                {name: 'VI',              value: 'VI',},
                {name: 'VII',              value: 'VII',},
                {name: 'VIII',              value: 'VIII',},
                {name: 'IX',              value: 'IX',},
                {name: 'X',              value: 'X',},
            ],
            cabinetDDL:[],
            drawerDDL:[],
            paycardHistoryDDL:[],
            disableLibrarian:false,

            hasEditSalaryAccess: true,
            hasViewSalaryAccess: false,
            hasEditPayCardTypeAccess: true,
            hasViewPayCardTypeAccess: false,
            hasEditPayCardNumberAccess: true,
            hasViewPayCardNumberAccess: false,

            paycardstatusId:"",
            paycardStatus:"",
            paycardStatusDDL:[
                {id:"0",name:"PROCESS"},
                {id:"1",name:"HOLD"},
                {id:"2",name:"CLEARANCE CLOSED"},
            ],
            employeeDateStart : "",
            employeeDateEnd : "",
            testDate: "",
            atmCardNumber : "",
            disableDateStart : true,
            disableDateEnd : true,
            disableatmCardNumber : true,

            eddDate : "",
            disableEDD : true,
        }
        this.state.userinfo = JSON.parse(sessionStorage.getItem("userData"))
        
        //this.GetClients()
        //this.GetNationalities()
    } 
    componentDidMount(){
        this.state.statusId = this.state.userinfo.roleId === "1" ||  
                                this.state.userinfo.roleId === "8" ? "4" : "2"
        
        if(this.state.userinfo.roleId==="1"){
            this.state.hasEditSalaryAccess = false
            this.state.hasViewSalaryAccess = true
            this.state.hasEditPayCardTypeAccess = false
            this.state.hasViewPayCardTypeAccess = true
            this.state.hasEditPayCardNumberAccess = false
            this.state.hasViewPayCardNumberAccess = true
        }

        this.GetClients()
        //let d = new Date()
        //this.state.effectivityMinDate = d.setDate(d.getDate() + 1);
        //this.state.userinfo.roleId = "33"
        let isLibrarian = false
        isLibrarian = this.state.userinfo.roleId==="33" || this.state.userinfo.roleId==="34"
        this.state.disableLibrarian = isLibrarian ? true : false
    }
    GetClients = async() =>{
        this.setState({isloading:true})
        const params = {
            "IpAddress":"0.0.0.0",
            "ClientId":this.state.userinfo.clientId,
            "UserId":this.state.userinfo.userId,
            "EmployeeId":this.state.userinfo.roleId==="1" || 
                         this.state.userinfo.roleId==="8" ? "" : this.state.userinfo.employeeId
        }
        await axios
        .post(AppConfiguration.Setting().noserapiendpoint + "Client/GetClientList", params)
        .then(res => {
            const data = res.data;
            let arr = []
            if(this.state.userinfo.roleId!=="1"){
                let fieldaccess = JSON.parse(sessionStorage.getItem("userControlAccess"))
                if(fieldaccess.filter(x=>x.accessCode==="0001").length>0){
                    let access = fieldaccess.find(x=>x.accessCode==="0001" && x.controlAccessDetails.length>0)
                    //console.log("access")
                    //console.log(access)
                    if(access.controlAccessDetails.filter(x=>x.isClient==="1").length>0){
                        let brr = access.controlAccessDetails.filter(x=>x.isClient==="1")
                        //console.log("brr")
                        //console.log(brr)
                        arr = data.clients.filter(f => brr.some(item => item.referenceId === f.id))
                    }
                }
                this.setState({clientDDL : arr})
            }
            else{
                this.setState({clientDDL : data.clients})
            }
        })
        .catch(error=>{
            this.setState({
                isloading   :   false,
                alerttype   :   "Error!",
                isshow      :   true,
                color       :   "danger",
                message     :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
                fade        :   true
            })
        })
        this.GetEmployeeList()
    }
    GetEmployeeList = async() =>{
        this.setState({isloading:true})
        
        const params = {
            "IpAddress":"0.0.0.0",
            "ClientId":this.state.clientId,
            "UserId":this.state.userinfo.userId,
            "EmployeeId": this.state.userinfo.roleId==="1" || 
                          this.state.userinfo.roleId==="8" ? "" : this.state.userinfo.employeeId
        }
        await axios
        .post(AppConfiguration.Setting().noserapiendpoint + "Employee/GetEmployeeProfileFilter", params)
        .then(res => {
            const data = res.data;
            let arr = []
            if(this.state.userinfo.roleId!=="1"){
                let fieldaccess = JSON.parse(sessionStorage.getItem("userControlAccess"))
                if(fieldaccess.filter(x=>x.accessCode==="0001").length>0){
                    let access = fieldaccess.find(x=>x.accessCode==="0001" && x.controlAccessDetails.length>0)
                    if(access.controlAccessDetails.filter(x=>x.isClient==="1").length>0){
                        let brr = access.controlAccessDetails.filter(x=>x.isClient==="1")
                        //console.log("brr")
                        //console.log(brr)
                        arr = data.profiles.filter(f => brr.some(item => item.referenceId === f.clientId))
                    }
                }
                this.setState({employeeDDL : arr,employeeList : arr, isloading:false})
            }
            else{
                this.setState({employeeDDL : data.profiles,employeeList : data.profiles, isloading:false})
            }
            //this.setState({employeeDDL : data.profiles,employeeList : data.profiles})
            //this.GetNationalities()
        })
        .catch(error=>{
            this.setState({
                isloading   :   false,
                alerttype   :   "Error!",
                isshow      :   true,
                color       :   "danger",
                message     :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
                fade        :   true
            })
        })
    }
    GetLocations = async(client) =>{
        this.setState({isloading:true})

        const params = {
            "IpAddress":"0.0.0.0",
            "ClientId":this.state.userinfo.clientId,
            "UserId":this.state.userinfo.userId,
            "ClientName": client,
            "City": "",
            "Province": "",
            "Region": ""
    
        }
        axios
        .post(AppConfiguration.Setting().noserapiendpoint + "Client/GetClientLocations", params)
        .then(res => {
            const data = res.data;
            //console.log(data)
            this.setState({locationDDL : data.locations ? data.locations : []})
        })
        .catch(error=>{
           this.setState(  {
               isloading       :   false,
               alerttype       :   "Error!",
               isshow          :   true,
               color           :   "danger",
               message         :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
               fade            :   true
           })
       })
       this.GetParamountEmployees()
    }
    GetParamountEmployees = async() =>{
        const params = {
            "IpAddress":"0.0.0.0",
            "ClientId":this.state.clientId,
            "UserId":this.state.userinfo.userId,
        }
        await axios
        .post(AppConfiguration.Setting().noserapiendpoint + "Employee/GetCoorEmployees", params)
        .then(res => {
            const data = res.data;
            this.setState({approver2DDL : data.employees})
        })
        .catch(error=>{
            this.setState({
                isloading   :   false,
                alerttype   :   "Error!",
                isshow      :   true,
                color       :   "danger",
                message     :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
                fade        :   true
            })
        })
        this.GetEmployeeInformation()
    }
    GetEmployeeInformation = async() =>{
        const params = {
            "IpAddress":"0.0.0.0",
            "ClientId":this.state.clientId,
            "UserId":this.state.userinfo.userId,
            "EmployeeId":this.state.employeeId,
        }
        await axios
        .post(AppConfiguration.Setting().noserapiendpoint + "Employee/GetEmployeeInformation", params)
        .then(res => {
            const data = res.data;
            console.log(data)
            if(data.status==="1"){

                let fieldaccess = JSON.parse(sessionStorage.getItem("userControlAccess"))

                if(fieldaccess.filter(x=>x.accessCode==="0001").length>0){

                    if(fieldaccess.filter(x=>x.accessCode==="0001" && x.fieldName==="SalaryRate" ).length>0){
                        let access = fieldaccess.find(x=>x.accessCode==="0001" && x.fieldName==="SalaryRate")
                        if(access.controlAccessDetails.filter(x=>x.isClient==="1" && x.referenceId===data.clientId).length>0){
                            this.state.hasEditSalaryAccess = access.hasEdit==="1" ? false : true
                            this.state.hasViewSalaryAccess = access.hasView==="1" ? true : false
                        }
                        if(access.controlAccessDetails.filter(x=>x.isClient==="0" && x.referenceId===data.positionId).length>0){
                            this.state.hasEditSalaryAccess = access.hasEdit==="1" ? false : true
                            this.state.hasViewSalaryAccess = access.hasView==="1" ? true : false
                        }
                    }

                    if(fieldaccess.filter(x=>x.accessCode==="0001" && x.fieldName==="PayCardType" ).length>0){
                        let access = fieldaccess.find(x=>x.accessCode==="0001" && x.fieldName==="PayCardType")
                        if(access.controlAccessDetails.filter(x=>x.isClient==="1" && x.referenceId===data.clientId).length>0){
                            this.state.hasEditPayCardTypeAccess = access.hasEdit==="1" ? false : true
                            this.state.hasViewPayCardTypeAccess = access.hasView==="1" ? true : false
                        }
                        if(access.controlAccessDetails.filter(x=>x.isClient==="0" && x.referenceId===data.positionId).length>0){
                            this.state.hasEditSalaryAccess = access.hasEdit==="1" ? false : true
                            this.state.hasViewSalaryAccess = access.hasView==="1" ? true : false
                        }
                    }

                    if(fieldaccess.filter(x=>x.accessCode==="0001" && x.fieldName==="PayCardNumber" ).length>0){
                        let access = fieldaccess.find(x=>x.accessCode==="0001" && x.fieldName==="PayCardNumber")
                        if(access.controlAccessDetails.filter(x=>x.isClient==="1" && x.referenceId===data.clientId).length>0){
                            this.state.hasEditPayCardNumberAccess = access.hasEdit==="1" ? false : true
                            this.state.hasViewPayCardNumberAccess = access.hasView==="1" ? true : false
                        }
                        if(access.controlAccessDetails.filter(x=>x.isClient==="0" && x.referenceId===data.positionId).length>0){
                            this.state.hasEditSalaryAccess = access.hasEdit==="1" ? false : true
                            this.state.hasViewSalaryAccess = access.hasView==="1" ? true : false
                        }
                    }
                }


                this.setState({
                    employeedetails : data,
                    employeeNo:data.employeeNo,
                    profileStatus:data.profileStatus,
                    firstName:data.firstName,
                    middleName:data.middleName,
                    lastName:data.lastName,
                    nickName:data.nickName,
                    homeNumber:data.homePhoneNumber,
                    officeNumber:"",//this.state.employeedetails.nickName,
                    mobileNumber:data.mobileNumber,
                    emailAddress:data.emailAddress,
                    facebook:data.facebook,
                    twitter:data.twitter,
                    dateofBirth:data.dateOfBirth !=="" ? new Date(data.dateOfBirth) : "",
                    placeofBirth:data.placeOfBirth,
                    height:data.height,
                    weight:data.weight,
                    languageSpoken:data.languageSpoken,
                    hobbies:data.hobbies,
                    contactName:data.emergencyContactName,
                    contactNumber:data.emergencyContactNumber,
                    contactRelation:data.emergencyContactRelation,
                    sssNumber:data.sssNumber,
                    tinNumber:data.tinNumber,
                    phicNumber:data.phicNumber,
                    hdmfNumber:data.hdmfNumber,
                    arrangementId:data.livingArrangementId,
                    arrangement:data.livingArrangement,
                    bloodtypeId:data.bloodTypeId,
                    bloodtype:data.bloodType,
                    nationalityId:data.nationalityId,
                    nationality:data.nationality,
                    religionId:data.religionId,
                    religion:data.religion,
                    genderId:data.genderId,
                    gender:data.gender,
                    civilstatusId: data.civilStatusId,
                    civilstatus:data.civilStatus,
        
                    locationId:data.locationId,
                    positionId:data.positionId,
                    employmentId:data.employmentStatusId,
                    cardtypeId:data.payCardTypeId,
                    paytypeId:data.payTypeId,
                    paymodeId:data.payModeId,
                    periodtypeId:data.periodTypeId,
                    scheduleId:data.workScheduleId,
                    salaryRate:data.salaryRate,
                    seaRate:data.seaRate,
                    ecolaRate:data.ecolaRate,
                    colaRate:data.colaRate,
                    isdefaultLocation:data.isDefaultLocation,
                    cardNumber:data.payCardNumber,
                    dateHired:data.dateHired !=="" ? new Date(data.dateHired) : "",
                    dateResigned:data.dateResigned !=="" ? new Date(data.dateResigned) : "",
                    contractStart:data.contractDateStart !=="" ? new Date(data.contractDateStart) : "",
                    contractEnd:data.contractDateEnd !=="" ? new Date(data.contractDateEnd) : "",
                    location:data.location,
                    position:data.position,
                    employment:data.employmentStatus,
                    cardtype:data.payCardType,
                    paytype:data.payType,
                    paymode:data.payMode,
                    periodtype:data.periodType,
                    schedule:data.workSchedule,
                    approver1Id:data.approverId1,
                    approver2Id:data.approverId2,
                    approver1:data.approver1,
                    approver2:data.approver2,
                    remarks:data.remarks,
                    mobileNumberEffectivityDate:"",
                    civilStatusEffectivityDate:"",
                    locationEffectivityDate:"",
                    positionEffectivityDate:"",
                    employmentStatusEffectivityDate:"",
                    dateResignedEffectivityDate:"",
                    contractDateEndEffectivityDate:"",
                    payCardTypeEffectivityDate:"",
                    payCardNumberEffectivityDate:"",
                    payTypeEffectivityDate:"",
                    payModeEffectivityDate:"",
                    workScheduleEffectivityDate:"",
                    periodTypeEffectivityDate:"",
                    salaryRateEffectivityDate:"",
                    seaRateEffectivityDate:"",
                    eColaRateEffectivityDate:"",
                    colaRateEffectivityDate:"",
                    approver1EffectivityDate:"",
                    approver2EffectivityDate:"",
                    periodtypeDDL:data.payModeId!=="" ? this.state.periodtypeList.filter(x=>x.payModeId===data.payModeId) : this.state.periodtypeList,

                    hmoId:data.hmoId,
                    cabinetId:data.cabinetId,
                    drawerId:data.drawerId,
                    hmo:data.hmo,
                    cabinet:data.cabinet,
                    drawer:data.drawer,

                    suffixName:data.suffixName,
                    sssNumberRemarks:data.sssNumberRemarks,
                    tinNumberRemarks:data.tinNumberRemarks,
                    phicNumberRemarks:data.phicNumberRemarks,
                    hdmfNumberRemarks:data.hdmfNumberRemarks,

                    paycardstatusId:data.payCardStatusId,
                    paycardStatus:data.payCardStatus,
                    hmoDate:data.hmoDate ==="" ? "" : new Date(data.hmoDate),
                    atmCardNumber:data.atmCardNumber,
                    employeeDateStart: data.employmentStatusStartDate==="" ? "" : new Date(data.employmentStatusStartDate),
                    employeeDateEnd: data.employmentStatusEndDate==="" ? "" : new Date(data.employmentStatusEndDate),
                    eddDate:data.edDate==="" ? "" : new Date(data.edDate)
                })
                if(data.hmoId === "1"){
                    if(data.withATD === "1"){
                        this.setState({
                            isWithAtd : true
                        })
                    }else {
                        this.setState({
                            isWithAtd : false
                        })
                    }
                    this.setState({
                        disableWithHmmo : false,
                        hdmfNumberRemarks:data.hdmfNumberRemarks,
                        policyNumber:data.policyNumber,
                        cardNumberHmo:data.cardNumber,
                        assurance:data.hmoProvider,
                    })
                }else {
                    this.setState({
                        disableWithHmmo : true,
                    })
                }
                this.GetPayCardHistory()
            }
        })
        .catch(error=>{
            this.setState({
                isloading   :   false,
                alerttype   :   "Error!",
                isshow      :   true,
                color       :   "danger",
                message     :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
                fade        :   true
            })
        })
        this.GetEmployeeInformationDetails()
    }
    GetEmployeeInformationDetails = async() =>{
        const params = {
            "IpAddress":"0.0.0.0",
            "ClientId":this.state.userinfo.clientId,
            "UserId":this.state.userinfo.userId,
            "ProfileId":this.state.profileId,
            "EmployeeId":this.state.employeeId
        }
        await axios
        .post(AppConfiguration.Setting().noserapiendpoint + "Employee/GetEmployeeProfileDetails", params)
        .then(res => {
            const data = res.data;
            //console.log("employee details")
            //console.log(data)
            this.setState({
                addressGRID : data.employeeAddresses.length>0 ? data.employeeAddresses : [],
                backgroundGRID: data.employeeFamilyBackgrounds.length>0 ? data.employeeFamilyBackgrounds : [],
                educationGRID: data.employeeEducations.length>0 ? data.employeeEducations : [],
                employmentGRID:data.employmentRecords.length>0 ? data.employmentRecords : [],
                //isloading:false
            })
        })
        .catch(error=>{
            this.setState({
                isloading   :   false,
                alerttype   :   "Error!",
                isshow      :   true,
                color       :   "danger",
                message     :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
                fade        :   true
            })
        })
        this.GetNationalities()
    }
    GetNationalities = async()=> {
        const params = {
            "IpAddress"     :   "0.0.0.0",
            "ClientId"      :   this.state.userinfo.clientId,
            "UserId"        :   this.state.userinfo.userId,
            "SectionId"     :   "",
            "DepartmentId"  :   "",
            "Name"          :   ""
        };
        await axios.post(AppConfiguration.Setting().noserapiendpoint + "Maintenance/GetNationalities",  params)
        .then(res => {
            const data = res.data;
            this.setState({nationalityDDL:data.nationalities});
        })
        this.GetReligions()
    }
    GetReligions = async()=> {
        const params = {
            "IpAddress"     :   "0.0.0.0",
            "ClientId"      :   this.state.userinfo.clientId,
            "UserId"        :   this.state.userinfo.userId
        };
        await axios.post(AppConfiguration.Setting().noserapiendpoint + "Maintenance/GetReligions",  params)
        .then(res => {
            const data = res.data;
            this.setState({religionDDL:data.religions});
        })
        this.GetLivingArrangements()
    }
    GetLivingArrangements = async()=> {
        const params = {
            "IpAddress"     :   "0.0.0.0",
            "ClientId"      :   this.state.userinfo.clientId,
            "UserId"        :   this.state.userinfo.userId
        };
        await axios.post(AppConfiguration.Setting().noserapiendpoint + "Maintenance/GetLivingArrangements",  params)
        .then(res => {
            const data = res.data;
            this.setState({arrangementDDL:data.livingArrangements});
        })
        this.GetBloodTypes()
    }
    GetBloodTypes = async()=> {
        const params = {
            "IpAddress"     :   "0.0.0.0",
            "ClientId"      :   this.state.userinfo.clientId,
            "UserId"        :   this.state.userinfo.userId
        };
        await axios.post(AppConfiguration.Setting().noserapiendpoint + "Maintenance/GetBloodTypes",  params)
        .then(res => {
            const data = res.data;
            this.setState({bloodtypeDDL:data.bloodTypes});
        })
        this.GetPositions()
    }
    GetPositions = async()=> {
        const params = {
            "IpAddress"     :   "0.0.0.0",
            "ClientId"      :   this.state.userinfo.clientId,
            "UserId"        :   this.state.userinfo.userId,
            "SectionId"     :   "",
            "DepartmentId"  :   "",
            "Name"          :   ""
        };
        await axios.post(AppConfiguration.Setting().noserapiendpoint + "Maintenance/GetPositions",  params)
        .then(res => {
            const data = res.data;
            this.setState({positionDDL:data.positions});
        })
        this.GetPayrollTypes()
    }
    GetPayrollTypes = async()=> {
        const params = {
            "IpAddress"     :   "0.0.0.0",
            "ClientId"      :   this.state.userinfo.clientId,
            "UserId"        :   this.state.userinfo.userId
        };
        await axios.post(AppConfiguration.Setting().noserapiendpoint + "Timekeeping/GetPayrollTypes",  params)
        .then(res => {
            const data = res.data;
            this.setState({paytypeDDL:data.payrollTypes});
        })
        this.GetPayrollModes()
    }
    GetPayrollModes = async()=> {
        const params = {
            "IpAddress"     :   "0.0.0.0",
            "ClientId"      :   this.state.userinfo.clientId,
            "UserId"        :   this.state.userinfo.userId
        };
        await axios.post(AppConfiguration.Setting().noserapiendpoint + "Timekeeping/GetPayrollModes",  params)
        .then(res => {
            const data = res.data;
            this.setState({paymodeDDL:data.payrollModes});
        })
        this.GetWorkSchedules()
    }
    GetWorkSchedules = async()=> {
        const params = {
            "IpAddress"     :   "0.0.0.0",
            "ClientId"      :   this.state.userinfo.clientId,
            "UserId"        :   this.state.userinfo.userId
        };
        await axios.post(AppConfiguration.Setting().noserapiendpoint + "Timekeeping/GetWorkingSchedules",  params)
        .then(res => {
            const data = res.data;
            this.setState({scheduleDDL:data.workSchedules});
            //console.log(data)
        })
        this.GetPeriodTypes()
    }
    GetPeriodTypes = async()=> {
        const params = {
            "IpAddress"     :   "0.0.0.0",
            "ClientId"      :   this.state.userinfo.clientId,
            "UserId"        :   this.state.userinfo.userId,
            "PayModeId"     :   ""
        };
        await axios.post(AppConfiguration.Setting().noserapiendpoint + "Timekeeping/GetPeriodTypes",  params)
        .then(res => {
            const data = res.data;
            this.setState({periodtypeList:data.periodTypes});
            //console.log(data)
        })
        this.GetEmployeeStatusTypes()
    }
    GetEmployeeStatusTypes = async()=> {
        const params = {
            "IpAddress"     :   "0.0.0.0",
            "ClientId"      :   this.state.userinfo.clientId,
            "UserId"        :   this.state.userinfo.userId
        };
        await axios.post(AppConfiguration.Setting().noserapiendpoint + "Maintenance/GetEmployeeStatusTypes",  params)
        .then(res => {
            const data = res.data;
            this.setState({employmentDDL:data.statusTypes});
        })
        this.GetPayCardTypes()
    }
    GetPayCardTypes = async()=> {
        const params = {
            "IpAddress"     :   "0.0.0.0",
            "ClientId"      :   this.state.userinfo.clientId,
            "UserId"        :   this.state.userinfo.userId
        };
        await axios.post(AppConfiguration.Setting().noserapiendpoint + "Maintenance/GetPayCardTypes",  params)
        .then(res => {
            const data = res.data;
            this.setState({cardtypeDDL:data.payCardTypes});
        })
        this.GetRegions()
    }
    GetRegions = async () => {

        const params = {
            "IpAddress":"0.0.0.0",
            "ClientId":this.state.userinfo.clientId,
            "UserId":this.state.userinfo.userId,
            "Name":""
        };
        await
        axios
        .post(AppConfiguration.Setting().noserapiendpoint + "Maintenance/GetRegions",  params)
        .then(res => {
            let regionDDL = []
            res.data.regions.map(function(itm){
                regionDDL.push({
                    value : itm.id,
                    label : itm.name,
                })
            })
            this.setState({regionDDL:regionDDL})
        })
        .catch(error=>{
            this.setState({
                isloading   :   false,
                alerttype   :   "Error!",
                isshow      :   true,
                color       :   "danger",
                message     :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
                fade        :   true
            })
        })
        this.GetProvinces()
    }
    GetProvinces = async () => {
        const params = {
            "IpAddress":"0.0.0.0",
            "ClientId":this.state.userinfo.clientId,
            "UserId":this.state.userinfo.userId,
            "Region": ""
        };
        await
        axios
        .post(AppConfiguration.Setting().noserapiendpoint + "Maintenance/GetProvinces",  params)
        .then(res => {
            let provinceDDL = []
            res.data.provinces.map(function(itm){
                provinceDDL.push({
                    value : itm.id,
                    label : itm.name,
                    regionId:itm.regionId
                })
            })
            this.setState({provinceDDL:provinceDDL})
        })
        .catch(error=>{
            this.setState({
                isloading   :   false,
                alerttype   :   "Error!",
                isshow      :   true,
                color       :   "danger",
                message     :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
                fade        :   true
            })
        })
        this.GetCities()
    }
    GetCities = async () => {

        const params = {
            "IpAddress":"0.0.0.0",
            "ClientId":this.state.userinfo.clientId,
            "UserId":this.state.userinfo.userId,
            "Name":"",
            "ProvinceName": ""
        };
        await
         axios
        .post(AppConfiguration.Setting().noserapiendpoint + "Maintenance/GetCities",  params)
        .then(res => {
            let cityDDL = []
            res.data.cities.map(function(itm){
                cityDDL.push({
                    value : itm.id,
                    label : itm.name,
                    provinceId:itm.provinceId
                })
            })
            this.setState({cityDDL:cityDDL,isloading:false})
        })
        .catch(error=>{
            this.setState({
                isloading   :   false,
                alerttype   :   "Error!",
                isshow      :   true,
                color       :   "danger",
                message     :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
                fade        :   true
            })
        })
        this.GetCabinets()
    }
    GetCabinets = async() =>{
        this.setState({isloading:true,isDisable:true})
        const params = {
            "IpAddress":"0.0.0.0",
            "ClientId":this.state.userinfo.clientId,
            "UserId":this.state.userinfo.userId,
            //"Name" : this.state.roleName
        }
        await axios
        .post(AppConfiguration.Setting().noserapiendpoint + "Maintenance/GetCabinets", params)
        .then(res => {
            const data = res.data
            if(data.status=="1"){
                this.setState({
                    isloading   :   false,
                    cabinetDDL     :   data.cabinets
                });
            }
            else{
                this.setState({
                    isloading   :   false,
                    alerttype   :   "Error!",
                    isshow      :   true,
                    color       :   "danger",
                    message     :   data.message,
                    fade        :   true,
                    cabinetDDL     :   data.cabinets
                });
            }
        })
        .catch(error=>{
            this.setState({
                isloading   :   false,
                alerttype   :   "Error!",
                isshow      :   true,
                color       :   "danger",
                message     :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
                fade        :   true,
            })
        })
        this.GetDrawers()
    }
    GetDrawers = async() =>{
        this.setState({isloading:true,isDisable:true})
        const params = {
            "IpAddress":"0.0.0.0",
            "ClientId":this.state.userinfo.clientId,
            "UserId":this.state.userinfo.userId,
            "Name" : this.state.name
        }
        //console.log(params)
        await axios
        .post(AppConfiguration.Setting().noserapiendpoint + "Maintenance/GetDrawers", params)
        .then(res => {
            const data = res.data
            if(data.status=="1"){
                this.setState({
                    isloading   :   false,
                    drawerDDL     :   data.drawers
                });
            }
            else{
                this.setState({
                    isloading   :   false,
                    alerttype   :   "Error!",
                    isshow      :   true,
                    color       :   "danger",
                    message     :   data.message,
                    fade        :   true,
                    drawerDDL     :   data.drawers
                });
            }
        })
        .catch(error=>{
            this.setState({
                isloading   :   false,
                alerttype   :   "Error!",
                isshow      :   true,
                color       :   "danger",
                message     :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
                fade        :   true,
            })
        })
    }
    GetPayCardHistory = async()=> {
        const params = {
            "IpAddress"     :   "0.0.0.0",
            "ClientId"      :   this.state.userinfo.clientId,
            "UserId"        :   this.state.userinfo.userId,
            "EmployeeId"    :   this.state.employeeId
        };
        await axios.post(AppConfiguration.Setting().noserapiendpoint + "Manning/GetEmployeePayCards",  params)
        .then(res => {
            const data = res.data;
            this.setState({paycardHistoryDDL:data.payCards});
            //console.log(data)
        })
    }
    handleChangedClient = e => {
        this.state.alerttype="";this.state.isshow=false;this.state.color="";this.state.message="";this.state.fade=true;
        if(e.length===0){
            this.state.clientId= ''
            this.state.client=''
            this.state.employeeDDL=this.state.employeeList
            //this.state.employeeDDL=[]
            //this.GetEmployeeList()
            return
        }
        this.state.clientId=e[0].id
        this.setState({employeeDDL:this.state.employeeList.filter(x=>x.clientId===e[0].id),client:e[0].name})
        //this.GetEmployeeList()
    }
    handleKeyDownClient = (e) => {
        if (e.key === 'Enter') {
            
        }
    }
    handleChangedEmployee = (e) =>{
        this.setState({alerttype:"",isshow:false,color:"",message:"",fade:true});
        
        if(e.length===0){
            this.state.employeeId= ''
            this.state.profileId= ''
            this.state.employeeName=''
            this.state.disablebutton = true
            return
        }
        this.state.disablebutton=true
        this.state.employeeId=""
        this.state.profileId=""
        this.state.employeeName=""
        this.state.profileStatus=""
        this.state.firstName=""
        this.state.middleName=""
        this.state.lastName=""
        this.state.nickName=""
        this.state.homeNumber=""
        this.state.officeNumber=""
        this.state.mobileNumber=""
        this.state.emailAddress=""
        this.state.facebook=""
        this.state.twitter=""
        this.state.dateofBirth=""
        this.state.placeofBirth=""
        this.state.height=""
        this.state.weight=""
        this.state.languageSpoken=""
        this.state.hobbies=""
        this.state.contactName=""
        this.state.contactNumber=""
        this.state.contactRelation=""
        this.state.sssNumber=""
        this.state.tinNumber=""
        this.state.phicNumber=""
        this.state.hdmfNumber=""
        this.state.arrangementId=""
        this.state.arrangement=""
        this.state.bloodtypeId=""
        this.state.bloodtype=""
        this.state.nationalityId=""
        this.state.nationality=""
        this.state.religionId=""
        this.state.religion=""
        this.state.genderId=""
        this.state.gender=""
        this.state.civilstatusId= ""
        this.state.civilstatus=""

        this.state.locationId=""
        this.state.positionId=""
        this.state.employmentId=""
        this.state.cardtypeId=""
        this.state.paytypeId=""
        this.state.paymodeId=""
        this.state.salaryRate=""
        this.state.seaRate=""
        this.state.ecolaRate=""
        this.state.colaRate=""
        this.state.isdefaultLocation=""
        this.state.cardNumber=""
        this.state.dateHired=""
        this.state.dateResigned=""
        this.state.contractStart=""
        this.state.contractEnd=""
        this.state.location=""
        this.state.position=""
        this.state.employment=""
        this.state.cardtype=""
        this.state.paytype=""
        this.state.paymode=""
        this.state.approver1Id=""
        this.state.approver2Id=""
        this.state.approver1=""
        this.state.approver2=""
        this.state.remarks=""

        this.state.hmoId="0"
        this.state.cabinetId=""
        this.state.drawerId=""
        this.state.hmo=""
        this.state.hmoDate=""
        this.state.cabinet=""
        this.state.drawer=""

        this.state.suffixName=""
        this.state.sssNumberRemarks=""
        this.state.tinNumberRemarks=""
        this.state.phicNumberRemarks=""
        this.state.hdmfNumberRemarks=""

        this.state.employeeDateStart=""
        this.state.employeeDateEnd=""
        this.state.eddDate=""

        this.state.employmentGRID=[]
        this.state.backgroundGRID=[]
        this.state.addressGRID=[]
        this.state.educationGRID=[]
        this.state.backgroundGRIDDel=[]
        this.state.addressGRIDDel=[]
        this.state.educationGRIDDel=[]
    
        this.state.employeeId= e[0].id
        this.state.profileId= e[0].profileId
        this.state.employeeName=e[0].employeeName
        this.state.clientId = e[0].clientId
        this.state.client = e[0].clientName
        this.GetLocations(e[0].clientName)
        this.state.approver1DDL = this.state.employeeDDL.filter(x=>x.id!==this.state.employeeId && x.status==="ACTIVE")
        this.state.disablebutton = false

        this.state.assuranceId=""
        this.state.cardNumberHmo=""
        this.state.policyNumber=""
        this.state.isWithAtd=""
        this.state.assurance=""
        this.refs.refassuranceId.clear()
        this.refs.refhmoId.clear()

    }
    handleChangedSuffixName = (e) => {
        this.setState({ suffixName: e.target.value,isshow:false, });
    };
    handleChangedHMO = (e) =>{
        this.state.alerttype="";this.state.isshow=false;this.state.color="";this.state.message="";this.state.fade=true;this.state.isloading=false
        if(e.length===0) {
            this.state.hmoId=""
            this.state.hmo=""
            return
        }
        this.state.hmoId=e[0].id
        this.state.hmo=e[0].name
        //console.log(this.state.hmoId)
        if(this.state.hmoId === "1"){
            this.setState({
                disableWithHmmo : false,
            })
        }else {
            this.refs.refassuranceId.clear()
            this.setState({
                disableWithHmmo : true,
                cardNumberHmo : "",
                policyNumber : "",
                isWithAtd : "",
            })
        }
    }
    handleChangedCardNumber = (e) =>{
        this.setState({ cardNumberHmo: e.target.value,isshow:false} );
    }
    handleChangedPolicyNumber = (e) =>{
        this.setState({ policyNumber: e.target.value,isshow:false} );
    }
    handleChangedAssurance = (e) =>{
        this.state.alerttype="";this.state.isshow=false;this.state.color="";this.state.message="";this.state.fade=true;this.state.isloading=false
        if(e.length===0) {
            this.state.assuranceId=""
            this.state.assurance=""
            return
        }
        this.state.assuranceId=e[0].id
        this.state.assurance=e[0].name
    }
    handleChangedWithAtd = (e) =>{
        this.setState({ isWithAtd : e.target.checked })
       // this.setState({[e.target.name]: e.target.checked})
    }
    handleChangedCabinet = (e) =>{
        this.state.alerttype="";this.state.isshow=false;this.state.color="";this.state.message="";this.state.fade=true;this.state.isloading=false
        if(e.length===0) {
            this.state.cabinetId=""
            this.state.cabinet=""
            return
        }
        this.state.cabinetId=e[0].id
        this.state.cabinet=e[0].name
    }
    handleChangedDrawer = (e) =>{
        this.state.alerttype="";this.state.isshow=false;this.state.color="";this.state.message="";this.state.fade=true;this.state.isloading=false
        if(e.length===0) {
            this.state.drawerId=""
            this.state.drawer=""
            return
        }
        this.state.drawerId=e[0].id
        this.state.drawer=e[0].name
    }
    handleChangedNationality = (e) =>{
        this.state.alerttype="";this.state.isshow=false;this.state.color="";this.state.message="";this.state.fade=true;this.state.isloading=false
        if(e.length===0) {
            this.state.nationalityId=""
            this.state.nationality=""
            return
        }
        this.state.nationalityId=e[0].id
        this.state.nationality=e[0].name
    }
    handleChangedReligion = (e) =>{
        this.state.alerttype="";this.state.isshow=false;this.state.color="";this.state.message="";this.state.fade=true;this.state.isloading=false
        if(e.length===0) {
            this.state.religionId=""
            this.state.religion=""
            return
        }
        this.state.religionId=e[0].id
        this.state.religion=e[0].name
    }
    handleChangedGender = (e) =>{
        this.state.alerttype="";this.state.isshow=false;this.state.color="";this.state.message="";this.state.fade=true;this.state.isloading=false
        if(e.length===0) {
            this.state.genderId=""
            this.state.gender=""
            return
        }
        this.state.genderId=e[0].id
        this.state.gender=e[0].name
    }
    handleChangedCivilStatus = (e) =>{
        this.state.alerttype="";this.state.isshow=false;this.state.color="";this.state.message="";this.state.fade=true;this.state.isloading=false
        if(e.length===0) {
            this.state.civilstatusId=""
            this.state.civilstatus=""
            return
        }
        this.state.civilstatusId=e[0].id
        this.state.civilstatus=e[0].name
    }
    handleChangedArrangement = (e) =>{
        this.state.alerttype="";this.state.isshow=false;this.state.color="";this.state.message="";this.state.fade=true;this.state.isloading=false
        if(e.length===0) {
            this.state.arrangementId=""
            this.state.arrangement=""
            return
        }
        this.state.arrangementId=e[0].id
        this.state.arrangement=e[0].name
    }
    handleChangedBloodType = (e) =>{
        this.state.alerttype="";this.state.isshow=false;this.state.color="";this.state.message="";this.state.fade=true;this.state.isloading=false
        if(e.length===0) {
            this.state.bloodtypeId=""
            this.state.bloodtype=""
            return
        }
        this.state.bloodtypeId=e[0].id
        this.state.bloodtype=e[0].name
    }
    handleChangedLocation = (e) =>{
        this.state.alerttype="";this.state.isshow=false;this.state.color="";this.state.message="";this.state.fade=true;this.state.isloading=false
        if(e.length===0) {
            this.state.locationId=""
            this.state.location=""
            return
        }
        this.state.locationId=e[0].id
        this.state.location=e[0].name
    }
    handleChangedPosition = (e) =>{
        this.state.alerttype="";this.state.isshow=false;this.state.color="";this.state.message="";this.state.fade=true;this.state.isloading=false
        if(e.length===0) {
            this.state.positionId=""
            this.state.position=""
            return
        }
        this.state.positionId=e[0].id
        this.state.position=e[0].name
    }
    handleChangedEmployment = (e) =>{
        this.state.alerttype="";this.state.isshow=false;this.state.color="";this.state.message="";this.state.fade=true;this.state.isloading=false
        this.state.employeeDateStart=""
        this.state.employeeDateEnd=""
        this.state.eddDate=""
        if(e.length===0) {
            this.state.employmentId=""
            this.state.employment=""
            return
        }
        if(e[0].id==="3" || 
            e[0].id==="5" || 
            e[0].id==="11" ||
            e[0].id==="16" ||
            e[0].id==="17" ||
            e[0].id==="22" ||
            e[0].id==="23" ||
            e[0].id==="32" ||
            e[0].id==="24"){
            this.setState({disableresigned:false})
        }else if(e[0].id==="6" || e[0].id==="19" || e[0].id==="21" || e[0].id==="30"){
            this.setState({
                disableDateStart:false,
                disableresigned:true,
            })
        }else if(e[0].id==="20"){
            this.setState({
                disableDateEnd:false,
                disableDateStart:false,
                disableresigned:true,
            })
        }
        else{
            this.setState({
                disableresigned:true,
                disableDateStart:true,
                disableDateEnd :true,
            })
        }
        if (e[0].id !=="20"){
            this.setState({ disableDateEnd : true})
        }
        if (e[0].id ==="19" || e[0].id==="30"){
            this.setState({ disableEDD : false})
        }else{
            this.setState({ disableEDD : true})
        }

        this.state.employmentId=e[0].id
        this.state.employment=e[0].name
    }
    handleChangedPayCardStatus = (e) =>{
        this.state.alerttype="";this.state.isshow=false;this.state.color="";this.state.message="";this.state.fade=true;this.state.isloading=false
        if(e.length===0) {
            this.state.paycardstatusId=""
            this.state.paycardStatus=""
            return
        }
        this.state.paycardstatusId=e[0].id
        this.state.paycardStatus=e[0].name
    }
    handleChangedPayCardType = (e) =>{
        this.state.alerttype="";this.state.isshow=false;this.state.color="";this.state.message="";this.state.fade=true;this.state.isloading=false
        if(e.length===0) {
            this.state.cardtypeId=""
            this.state.cardtype=""
            this.state.cardNumber=""
            return
        }
        // if(e[0].id==="4"){
        //     this.state.disablecard=false
        //     this.state.cardNumber=""
        // }
        // if(e[0].id!=="4"){
        //     this.state.disablecard=false
        // }
        this.state.cardtypeId=e[0].id
        this.state.cardtype=e[0].name
        if(this.state.paycardHistoryDDL.filter(x=>x.payCardTypeId===e[0].id).length>0){
            //alert(this.state.paycardHistoryDDL.find(x=>x.payCardTypeId===e[0].id).payCardNumber)
            //this.state.cardNumber = this.state.paycardHistoryDDL.find(x=>x.payCardTypeId===e[0].id).payCardNumber
            this.setState({cardNumber:this.state.paycardHistoryDDL.find(x=>x.payCardTypeId===e[0].id).payCardNumber})
        }
        else{
            this.setState({cardNumber:""})
        };

        if(e[0].name === "METROBANK" || e[0].name === "UNION BANK"){
            this.setState({disableatmCardNumber: false})
        }else{
            this.setState({disableatmCardNumber: true})
        }
    };

    handleChangedatmCardNumber =(e)=>{
        this.setState({atmCardNumber : e.target.value})
        console.log(e.target.value)
    };

    handleChangedPayType = (e) =>{
        this.state.alerttype="";this.state.isshow=false;this.state.color="";this.state.message="";this.state.fade=true;this.state.isloading=false
        if(e.length===0) {
            this.state.paytypeId=""
            this.state.paytype=""
            return
        }
        this.state.paytypeId=e[0].id
        this.state.paytype=e[0].name
    }
    handleChangedPayMode = (e) =>{
        this.state.alerttype="";this.state.isshow=false;this.state.color="";this.state.message="";this.state.fade=true;this.state.isloading=false
        if(e.length===0) {
            this.state.paymodeId=""
            this.state.paymode=""
            this.state.periodtypeDDL=this.state.periodtypeList
            return
        }
        this.state.paymodeId=e[0].id
        this.state.paymode=e[0].name
        this.setState({periodtypeDDL:this.state.periodtypeList.filter(x=>x.payModeId===e[0].id)})
    }
    handleChangedPeriodType = (e) => {
        this.state.alerttype="";this.state.isshow=false;this.state.color="";this.state.message="";this.state.fade=true;this.state.isloading=false
        if(e.length===0) {
            this.state.periodtypeId=""
            this.state.periodtype=""
            return
        }
        this.state.periodtypeId=e[0].id
        this.state.periodtype=e[0].name
        //alert(this.state.periodtypeId)
    }
    handleChangedSchedule = (e) => {
        this.state.alerttype="";this.state.isshow=false;this.state.color="";this.state.message="";this.state.fade=true;this.state.isloading=false
        if(e.length===0) {
            this.state.scheduleId=""
            this.state.schedule=""
            return
        }
        this.state.scheduleId=e[0].id
        this.state.schedule=e[0].description
        //alert(this.state.scheduleId)
    }
    handleChangedApprover1 = (e) =>{
        this.state.alerttype="";this.state.isshow=false;this.state.color="";this.state.message="";this.state.fade=true;this.state.isloading=false
        if(e.length===0) {
            this.state.approver1Id="0"
            this.state.approver1=""
            return
        }
        this.state.approver1Id=e[0].id
        this.state.approver1=e[0].employeeName
    }
    handleChangedApprover2 = (e) =>{
        this.state.alerttype="";this.state.isshow=false;this.state.color="";this.state.message="";this.state.fade=true;this.state.isloading=false
        if(e.length===0) {
            this.state.approver2Id=""
            this.state.approver2=""
            return
        }
        this.state.approver2Id=e[0].id
        this.state.approver2=e[0].employeeName
    }
    handleChangedHMODate = date =>{
        this.setState({hmoDate:date})
    }
    handleChangedDOB = date =>{
        this.setState({dateofBirth:date})
    }
    handleChangedDR = date =>{
        this.setState({dateResigned:date})
    }
    handleChangedEOC = date =>{
        this.setState({contractEnd:date})
    }
    handleChangedDDL = name => e =>{
        this.setState({alerttype:"",isshow:false,color:"",message:"",fade:true,isloading:false});
        if(e.length===0) {
            this.setState({[name]:""});
        }
        else{
            this.setState({[name]:e[0].id})

            if(name==="cardtypeId" && e[0].id==="4"){
                this.setState({disablecard:true,cardNumber:""})
            }
            if(name==="cardtypeId" && e[0].id!=="4"){
                this.setState({disablecard:false})
            }
            
            if(name==="employmentId"){
                if(e[0].id==="5" || 
                    e[0].id==="11" ||
                    e[0].id==="17" ||
                    e[0].id==="22" ||
                    e[0].id==="23" ||
                    e[0].id==="32" ||
                    e[0].id==="24"){
                        this.setState({disableresigned:false})
                }
                else{
                    this.setState({disableresigned:true})
                }
            }
        }
    };

    handleChangedDate = name => date =>{
        //employeeDateStart
       // console.log(this.state.employeeDateStart)
        this.setState({[name]:date})

        let tmpdate = moment(date).format('MM/DD/YYYY');
        let createdDate = new Date(date);

        if(this.state.employmentId === "6" && name==="employeeDateStart"){
            let createdDate1 = new Date(date);
            let floatingMonth = parseFloat(3);
            let fm = new Date(createdDate.setMonth(createdDate1.getMonth()+parseFloat(floatingMonth)));
            let floating = new Date(fm);
            this.setState({employeeDateEnd : floating })
        };
        if(this.state.employmentId === "19" && name==="employeeDateStart"){
            let createdDate2 = new Date(date);
            let maternityMonth = parseFloat(4);
            let mm = new Date(createdDate2.setMonth(createdDate2.getMonth()+parseFloat(maternityMonth)));
            let maternity = new Date(mm);
            this.setState({employeeDateEnd : maternity })
        };
        if(this.state.employmentId === "30" && name==="employeeDateStart"){
            let createdDate2 = new Date(date);
            let maternityMonth = parseFloat(3);
            let mm = new Date(createdDate2.setMonth(createdDate2.getMonth()+parseFloat(maternityMonth)));
            let maternity = new Date(mm);
            this.setState({employeeDateEnd : maternity })
        };
        if(this.state.employmentId === "21" && name==="employeeDateStart"){
            let createdDate4 = new Date(date);
            let paternityMonth = parseFloat(7);
            let pm = new Date(createdDate4.setDate(createdDate4.getDate()+parseFloat(paternityMonth)));
            let paternity = new Date(pm);
            this.setState({employeeDateEnd : paternity })
        };
        if(this.state.employmentId !== "20"){
            this.setState({disableDateEnd: true})
        };
        
        // }else if(this.state.employeeDateStart === ""){
        //     return
        //    // this.setState({employeeDateEnd : "" })
        // }

 
      
    };

    //testclick=(date)=>{
        // console.log("testclick")
        // console.log(this.state.cardtypeId)
        //console.log(this.state.employeeDateStart)
        //console.log(this.state.employeeDateEnd)
      /*   console.log("this.state.employeeDateStart 123")
        console.log(this.state.employeeDateStart)
        //console.log(name + ""+ "" +date)
        // new Date(data.dateHired
        console.log(new Date(date)) */


        //maternity 19
        //floatin 6
        //paternity 21
        //sickleave 20

       /*  console.log("++++++++++++++++++")
        console.log(this.state.employmentId)
        if(this.state.employmentId === "6" || this.state.employmentId === "19" || this.state.employmentId === "20" || this.state.employmentId === "21" ){
            console.log("true")
        }else{
            console.log("false")
        } */
   // }
/* 1234 */
    handleChanged = (e) =>{
        if(e.target.name==="salaryRate" ||
            e.target.name==="ecolaRate" ||
            e.target.name==="colaRate" ||
            e.target.name==="seaRate"){
            if(e.target.value.split(".").length>2) return
            let dec = e.target.value.split(".")[1]
            if(typeof dec !='undefined')
                if(dec.length>2) return

        };
        //this.state[e.target.name] = e.target.value
        this.setState({[e.target.name]:e.target.value})
        
        
    }
    handleChangedCB = (e) =>{
        this.setState({[e.target.name]: e.target.checked})
    };

    FormatDate(date) {
        let m = moment(date, 'MM-DD-YYYY');
        return (m.isValid() ? (('0' + (date.getMonth()+1)).slice(-2) + "/" + ('0' + (date.getDate())).slice(-2) + "/" + date.getFullYear()) : "")
    };

    ValidNumeric(val)
    {
        var regex = /^\d+(.\d+)?$/;
        return !regex.test(val)
    }
    IsNumeric(evt)
    {
        var theEvent = evt || window.event;
        var key = theEvent.keyCode || theEvent.which;
        key = String.fromCharCode( key );
        var regex = /^\d+(.\d+)?$/;
        if( !regex.test(key) ) {
        theEvent.returnValue = false;
        if(theEvent.preventDefault) theEvent.preventDefault();
        }
        
    }
    IsDecimal(evt)
    {
        var theEvent = evt || window.event;
        var key = theEvent.keyCode || theEvent.which;
        key = String.fromCharCode( key );
        var regex = /^\d*\.?(?:\d{1,2})?$/;
        if( !regex.test(key) ) {
        theEvent.returnValue = false;
        if(theEvent.preventDefault) theEvent.preventDefault();
        }
    }
    handleAddAddress = () =>{
        if(this.state.profileId===""){
            this.setState({
                isloading   :   false,
                alerttype   :   "Error!",
                isshow      :   true,
                color       :   "danger",
                message     :   "No selected employee.",
                fade        :   true
            })
            return
        }
        let addressGRID = this.state.addressGRID
        let newId = 0;
        if(addressGRID.length===0){
            let today = new Date()
            newId = parseInt(moment(today).format('HHMMSS'));
        }
        else{
            let today = new Date()
            const ids = addressGRID.map(x => x.id);
            const sorted = ids.sort((a, b) => a - b);
            const uid = moment(today).format('SS');
            newId = parseInt(sorted[sorted.length-1])+1//+uid;
        }

        addressGRID.push({
            "profileId":this.state.profileId,
            "id" :newId.toString(),
            "typeId":"",
            "regionId" : "",
            "region" : "",
            "provinceId" : "",
            "province" : "",
            "cityId" : "",
            "city" : "",
            "houseNumber" : "",
            "streetName" : "",
            "barangay" : "",
            "postalCode" : "",
            "isModified" : "1",
            "isDeleted" : "0"
        })
        this.setState({addressGRID:addressGRID})
    }
    handleRemoveAddress = (id) =>{
        let addressGRID = this.state.addressGRID
        let addressGRIDDel = this.state.addressGRIDDel
        addressGRID.map(function(itm){
            if(itm.id===id){
                itm.isDeleted="1"
                itm.isModified="1"
                addressGRIDDel.push(itm)
            }
        })
        this.setState({
            addressGRID:addressGRID.filter(x=>x.isDeleted==="0")
        })
    }
    handleClickBackground = () =>{
        if(this.state.profileId===""){
            this.setState({
                isloading   :   false,
                alerttype   :   "Error!",
                isshow      :   true,
                color       :   "danger",
                message     :   "No selected employee.",
                fade        :   true
            })
            return
        }
        let backgroundGRID = this.state.backgroundGRID
        let newId = 0;
        if(backgroundGRID.length===0){
            let today = new Date()
            newId = parseInt(moment(today).format('HHMMSS'));
        }
        else{
            let today = new Date()
            const ids = backgroundGRID.map(x => x.id);
            const sorted = ids.sort((a, b) => a - b);
            const uid = moment(today).format('SS');
            newId = parseInt(sorted[sorted.length-1])+1//+uid;
        }

        backgroundGRID.push({
            "profileId":this.state.profileId,
            "id" :newId.toString(),
            "roleId":"",
            "name" : "",
            "age" : "",
            "occupation" : "",
            "company" : "",
            "isModified" : "1",
            "isDeleted" : "0"
        })
        this.setState({backgroundGRID:backgroundGRID})
    }
    handleClickBackgroundRemove = (id) =>{
        let backgroundGRID = this.state.backgroundGRID
        let backgroundGRIDDel = this.state.backgroundGRIDDel
        backgroundGRID.map(function(itm){
            if(itm.id===id){
                itm.isDeleted="1"
                itm.isModified="1"
                backgroundGRIDDel.push(itm)
            }
        })
        this.setState({backgroundGRID:backgroundGRID.filter(x=>x.isDeleted==="0")})
    }

    handleAddEducation = () =>{
        
        if(this.state.profileId===""){
            this.setState({
                isloading   :   false,
                alerttype   :   "Error!",
                isshow      :   true,
                color       :   "danger",
                message     :   "No selected employee.",
                fade        :   true
            })
            return
        }
        let educationGRID = this.state.educationGRID
        let newId = 0;
        if(educationGRID.length===0){
            let today = new Date()
            newId = parseInt(moment(today).format('HHMMSS'));
        }
        else{
            let today = new Date()
            const ids = educationGRID.map(x => x.id);
            const sorted = ids.sort((a, b) => a - b);
            const uid = moment(today).format('SS');
            newId = parseInt(sorted[sorted.length-1])+1+uid;
        }

        educationGRID.push({
            "profileId":this.state.profileId,
            "id" :newId.toString(),
            "levelId":"",
            "schoolName" : "",
            "course" : "",
            "startYear" : "",
            "endYear" : "",
            "honorRecieved" : "",
            "isModified" : "1",
            "isDeleted" : "0"
        })
        this.setState({educationGRID:educationGRID})
    }
    handleRemoveEducation = (id) =>{
        let educationGRID = this.state.educationGRID
        let educationGRIDDel = this.state.educationGRIDDel
        educationGRID.map(function(itm){
            if(itm.id===id){
                itm.isDeleted="1"
                itm.isModified="1"
                educationGRIDDel.push(itm)
            }
        })
        this.setState({educationGRID:educationGRID.filter(x=>x.isDeleted==="0")})
    };

    handleSubmitClick = async () => {
        this.setState({
            isloading   :   true,
            isshow      :   false,
            fade        :   true,
            disablebutton:  true,
            color       :   "",
            message     :   "",
            alerttype   :   ""
        })

        let address = this.state.addressGRID.concat(this.state.addressGRIDDel)
        let familyBackgrounds = this.state.backgroundGRID.concat(this.state.backgroundGRIDDel)
        let educations = this.state.educationGRID.concat(this.state.educationGRIDDel)
        
        if(address.filter(x=>x.typeId!=="" && x.regionId==="" && x.isDeleted==="0").length>0){
            this.setState({
                isloading   :   false,
                alerttype   :   "Error!",
                isshow      :   true,
                color       :   "danger",
                message     :   "Please review address details for some invalid inputs.",
                fade        :   true,
                disablebutton:  false
            });
            return
        }
        if(address.filter(x=>x.typeId!=="" && x.provinceId==="" && x.isDeleted==="0").length>0){
            this.setState({
                isloading   :   false,
                alerttype   :   "Error!",
                isshow      :   true,
                color       :   "danger",
                message     :   "Please review address details for some invalid inputs.",
                fade        :   true,
                disablebutton:  false
            });
            return
        }

        if(address.filter(x=>x.typeId!=="" && x.cityId==="" && x.isDeleted==="0").length>0){
            this.setState({
                isloading   :   false,
                alerttype   :   "Error!",
                isshow      :   true,
                color       :   "danger",
                message     :   "Please review address details for some invalid inputs.",
                fade        :   true,
                disablebutton:  false
            });
            return
        }

        if(educations.filter(x=>x.levelId!=="" && x.startYear==="" && x.isDeleted==="0").length>0){
            this.setState({
                isloading   :   false,
                alerttype   :   "Error!",
                isshow      :   true,
                color       :   "danger",
                message     :   "Please review education details for some invalid inputs.",
                fade        :   true,
                disablebutton:  false
            });
            return
        }

        if(educations.filter(x=>x.levelId!=="" && x.endYear==="" && x.isDeleted==="0").length>0){
            this.setState({
                isloading   :   false,
                alerttype   :   "Error!",
                isshow      :   true,
                color       :   "danger",
                message     :   "Please review education details for some invalid inputs.",
                fade        :   true,
                disablebutton:  false
            });
            return
        }

        if(this.state.employeeId==='' || this.state.profileId===''){
            this.setState({
                isloading   :   false,
                alerttype   :   "Error!",
                isshow      :   true,
                color       :   "danger",
                message     :   "Please select employee name.",
                fade        :   true,
                disablebutton:  false
            });
            return
        }
        if(this.state.hmoId==='1' || this.state.hmoId==='3'){
            if(this.state.hmoDate===''){
                this.setState({
                    isloading   :   false,
                    alerttype   :   "Error!",
                    isshow      :   true,
                    color       :   "danger",
                    message     :   "Please select hmo date.",
                    fade        :   true,
                    disablebutton:  false
                });
                return
            }
        }
        
        const params = {
            "IpAddress":"0.0.0.0",
            "ClientId":this.state.userinfo.clientId,
            "UserId":this.state.userinfo.userId,
            "RoleId":this.state.userinfo.roleId,
            "UserEmployeeId":this.state.userinfo.employeeId,

            "EmployeeId":this.state.employeeId,
            "ProfileId":this.state.profileId,
            "EmployeeName":this.state.employeeName,
            
            "FirstName":this.state.firstName,
            "MiddleName":this.state.middleName,
            "LastName":this.state.lastName,
            "NickName":this.state.nickName,
            "PhoneNumber":this.state.homeNumber,
            "MobileNumber":this.state.mobileNumber,
            "EmailAddress":this.state.emailAddress,
            "DateOfBirth":this.FormatDate(this.state.dateofBirth),
            "PlaceOfBirth":this.state.placeofBirth,
            "NationalityId":this.state.nationalityId,
            "ReligionId":this.state.religionId,
            "GenderId":this.state.genderId,
            "Gender":this.state.gender,
            "CivilStatusId":this.state.civilstatusId,
            "CivilStatus":this.state.civilstatus,
            "LivingArrangementId":this.state.arrangementId,
            "BloodTypeId":this.state.bloodtypeId,
            "Height":this.state.height,
            "Weight":this.state.weight,
            "Hobbies":this.state.hobbies,
            "LanguageSpoken":this.state.languageSpoken,
            "Facebook":this.state.facebook,
            "Twitter":this.state.twitter,
            "SSSNumber":this.state.sssNumber,
            "PHICNumber":this.state.phicNumber,
            "HDMFNumber":this.state.hdmfNumber,
            "TINNumber":this.state.tinNumber,
            "EmergencyContactName":this.state.contactName,
            "EmergencyContactNumber":this.state.contactNumber,
            "EmergencyContactRelation":this.state.contactRelation,
            
            "IsDefaultLocation":this.state.isdefaultLocation ? "1" : "0",
            "LocationId":this.state.locationId,
            "PositionId":this.state.positionId,
            "EmploymentStatusTypeId":this.state.employmentId,
            "PayTypeId":this.state.paytypeId,
            "PayModeId":this.state.paymodeId,
            "PeriodTypeId":this.state.periodtypeId,
            "WorkScheduleId":this.state.scheduleId,
            "PayCardTypeId":this.state.cardtypeId,
            "PayCardNumber":this.state.cardNumber,
            "SalaryOffered":this.state.salaryRate,
            "SeaValue":this.state.seaRate,
            "EColaValue":this.state.ecolaRate,
            "ColaValue":this.state.colaRate,
            "DateHired":this.FormatDate(this.state.dateHired),
            "ContractDateStart":this.FormatDate(this.state.contractStart),
            "ContractDateEnd":this.FormatDate(this.state.contractEnd),
            "DateResigned":this.FormatDate(this.state.dateResigned),
            "ApproverId1":this.state.approver1Id,
            "ApproverId2":this.state.approver2Id,
            "Remarks":this.state.remarks,

            "MobileNumberEffectivityDate":this.FormatDate(this.state.mobileNumberEffectivityDate),
            "CivilStatusEffectivityDate":this.FormatDate(this.state.civilStatusEffectivityDate),
            "LocationEffectivityDate":this.FormatDate(this.state.locationEffectivityDate),
            "PositionEffectivityDate":this.FormatDate(this.state.positionEffectivityDate),
            "EmploymentStatusEffectivityDate":this.FormatDate(this.state.employmentStatusEffectivityDate),
            "DateResignedEffectivityDate":this.FormatDate(this.state.dateResignedEffectivityDate),
            "ContractDateEndEffectivityDate":this.FormatDate(this.state.contractDateEndEffectivityDate),
            "PayCardTypeEffectivityDate":this.FormatDate(this.state.payCardTypeEffectivityDate),
            "PayCardNumberEffectivityDate":this.FormatDate(this.state.payCardNumberEffectivityDate),
            "PayTypeEffectivityDate":this.FormatDate(this.state.payTypeEffectivityDate),
            "PayModeEffectivityDate":this.FormatDate(this.state.payModeEffectivityDate),
            "WorkScheduleEffectivityDate":this.FormatDate(this.state.workScheduleEffectivityDate),
            "PeriodTypeEffectivityDate":this.FormatDate(this.state.periodTypeEffectivityDate),
            "SalaryRateEffectivityDate":this.FormatDate(this.state.salaryRateEffectivityDate),
            "SeaRateEffectivityDate":this.FormatDate(this.state.seaRateEffectivityDate),
            "EColaRateEffectivityDate":this.FormatDate(this.state.eColaRateEffectivityDate),
            "ColaRateEffectivityDate":this.FormatDate(this.state.colaRateEffectivityDate),
            "Approver1EffectivityDate":this.FormatDate(this.state.approver1EffectivityDate),
            "Approver2EffectivityDate":this.FormatDate(this.state.approver2EffectivityDate),
            
            "address":address.filter(x=>x.typeId!=="" && x.isModified==="1"),
            "familyBackgrounds":familyBackgrounds.filter(x=>x.roleId!=="" && x.isModified==="1"),
            "educations":educations.filter(x=>x.levelId!=="" && x.isModified==="1"),

            "HMOId":this.state.hmoId,
            "HMODate":this.FormatDate(this.state.hmoDate),
            "CabinetId":this.state.cabinetId,
            "DrawerId":this.state.drawerId,

            "SuffixName":this.state.suffixName,
            "SSSNumberRemarks":this.state.sssNumberRemarks,
            "PHICNumberRemarks":this.state.phicNumberRemarks,
            "HDMFNumberRemarks":this.state.hdmfNumberRemarks,
            "TINNumberRemarks":this.state.tinNumberRemarks,

            "RequestDate":this.state.requestDate!=="" ? this.FormatDate(this.state.requestDate) : "",
            "PullOutDate":this.state.pullOutDate!=="" ? this.FormatDate(this.state.pullOutDate) : "",
            "ReturnDate":this.state.returnDate!=="" ? this.FormatDate(this.state.returnDate) : "",

            "CardNumber":this.state.cardNumberHmo,
            "PolicyNumber":this.state.policyNumber,
            "HmoProvider":this.state.assurance,
            "WithATD":(this.state.isWithAtd) ? "1" : "0",
            "PayCardStatusId":this.state.paycardstatusId,
            "EmploymentStatusStartDate" :this.FormatDate(this.state.employeeDateStart),
            "EmploymentStatusEndDate":this.FormatDate(this.state.employeeDateEnd),
            "AtmCardNumber":this.state.atmCardNumber,

            "EDDate":this.FormatDate(this.state.eddDate),

        };
        console.log("params")
        console.log(params)
        await
        axios
        .post(AppConfiguration.Setting().noserapiendpoint + "Manning/SubmitEmployeeInformation",  params)
        .then(res => {
            const data = res.data
            this.setState({isloading:false})
            if(data.status==="0"){
                this.setState({
                    isloading   :   false,
                    alerttype   :   "Error!",
                    isshow      :   true,
                    color       :   "danger",
                    message     :   data.message,
                    fade        :   true,
                    disablebutton:  false
                });
            } 
            else{
                this.setState({
                    isloading   :   false,
                    alerttype   :   "Success!",
                    isshow      :   true,
                    color       :   "success",
                    message     :   data.message,
                    fade        :   true
                });
            } 
        })
        .catch(error=>{
            this.setState({
                isloading   :   false,
                alerttype   :   "Error!",
                isshow      :   true,
                color       :   "danger",
                message     :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
                fade        :   true,
                disablebutton:  false
            })
        })
    };

    GridAddress(oldValue, newValue, id, column) {
        this.state.addressGRID.map(function(item,i) {
            if (item.id===id)
                item.isModified = newValue!=oldValue ? "1" : "0"
            })
    }
    GridEducation(oldValue, newValue, id, column) {
        this.state.educationGRID.map(function(item,i) {
            if (item.id===id)
                item.isModified = newValue!=oldValue ? "1" : "0"
            })
    }
    GridBackground(oldValue, newValue, id, column) {
        this.state.backgroundGRID.map(function(item,i) {
            if (item.id===id)
                item.isModified = newValue!=oldValue ? "1" : "0"
            })
    }
    handleActivateClick = async(row) =>{
        let stat = row.statusId === "0" ? "deactivate" : "activate"
        if (!window.confirm('Do you want to '+ stat +' this employment record?'))
            return
        this.setState({isloading:true})
        const params = {
            "IpAddress":"0.0.0.0",
            "ClientId":this.state.userinfo.clientId,
            "UserId":this.state.userinfo.userId,
            "ProfileId": row.profileId,
            "EmployeeId": row.id,
            "StatusId": row.statusId==="0" ? "1" : "0"
        }
        await axios
        .post(AppConfiguration.Setting().noserapiendpoint + "Employee/ActivateEmployeeRecord", params)
        .then(res => {
            const data = res.data;
            this.setState({
                //isloading           :   false,
                alerttype           :   data.status==="1" ? "Success!" : "Error!",
                isshow              :   true,
                color               :   data.status==="1" ? "success" : "danger",
                message             :   data.message,
                fade                :   true
            });
            if(data.status==="1"){
                this.GetEmployeeInformationDetails()
            }
        })
        .catch(error=>{
           this.setState(  {
               isloading       :   false,
               alerttype       :   "Error!",
               isshow          :   true,
               color           :   "danger",
               message         :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
               fade            :   true
           })
       })
    }
    render(){
        const addrCol = [
            {
                dataField: 'typeId',
                text: 'TYPE',
                editable: true,
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'left',width:'8%'}},
                style:{textAlign:'left',width:'8%'},
                formatter: (cell, row) => {
                    if(row.typeId!=='' || row.typeId!==null || typeof row.typeId!=='undefined'){
                        if(this.state.addrtypeDDL.filter(x => x.value == cell).length>0)
                            return this.state.addrtypeDDL.find(x => x.value == cell).label;
                    }
                },
                editor: {
                    type: Type.SELECT,
                    options: this.state.addrtypeDDL
                },
                validator: (newValue, row, column) => {
                    if(this.state.addressGRID.filter(x=>x.typeId===newValue).length>0)
                    return {
                        valid: false,
                        message:"Select address type already exist."
                      };
                    return true;   
                }
            },
            {
                dataField: 'regionId',
                text: 'REGION',
                editable: true,
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'left',width:'15%'}},
                style:{textAlign:'left',width:'15%'},
                formatter: (cell, row) => {
                    if(row.regionId!=='' || row.regionId!==null || typeof row.regionId!=='undefined'){
                        if(this.state.regionDDL.filter(x => x.value == cell).length>0)
                            return this.state.regionDDL.find(x => x.value == cell).label;
                    }
                },
                editor: {
                    type: Type.SELECT,
                    options: this.state.regionDDL
                },
                validator: (newValue, row, column) => {
                    if (row.typeId==="") {
                      return {
                        valid: false,
                        message:"Please select address type."
                      };
                    } 
                    return true;   
                }
            },
            {
                dataField: 'provinceId',
                text: 'PROVINCE',
                editable: true,
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'left',width:'15%'}},
                style:{textAlign:'left',width:'15%'},
                formatter: (cell, row) => {
                    if(row.provinceId!=='' && row.provinceId!==null || typeof row.provinceId!=='undefined'){
                        if(this.state.provinceDDL.filter(x => x.value == cell).length>0)
                            return this.state.provinceDDL.find(x => x.value == cell).label;
                    }
                },
                editor: {
                    type: Type.SELECT,
                    getOptions: (setOptions, { row, column }) => {
                        return this.state.provinceDDL.filter(x=>x.regionId==row.regionId);
                    }
                },
                validator: (newValue, row, column) => {
                    if (row.regionId==="") {
                      return {
                        valid: false,
                        message:"Please select region address."
                      };
                    } 
                    return true;   
                }
            },
            {
                dataField: 'cityId',
                text: 'CITY',
                editable: true,
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'left',width:'15%'}},
                style:{textAlign:'left',width:'15%'},
                formatter: (cell, row) => {
                    if(row.cityId!=='' && row.cityId!==null || typeof row.cityId!=='undefined'){
                        if(this.state.cityDDL.filter(x => x.value == cell).length>0)
                            return this.state.cityDDL.find(x => x.value == cell).label;
                    }
                },
                editor: {
                    type: Type.SELECT,
                    getOptions: (setOptions, { row, column }) => {
                        return this.state.cityDDL.filter(x=>x.provinceId==row.provinceId);
                    }
                },
                validator: (newValue, row, column) => {
                    if (row.provinceId==="") {
                      return {
                        valid: false,
                        message:"Please select province address."
                      };
                    } 
                    return true;   
                }
            },
            {
                dataField: 'houseNumber',
                text: 'House Number',
                editable: true,
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'left',width:'10%'}},
                style:{textAlign:'left',width:'10%'},
                validator: (newValue, row, column) => {
                    if (row.cityId==="") {
                      return {
                        valid: false,
                        message:"Please select city address."
                      };
                    } 
                    return true;   
                }
            },
            {
                dataField: 'streetName',
                text: 'STREET NAME',
                editable: true,
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'left',width:'10%'}},
                style:{textAlign:'left',width:'10%'},
                validator: (newValue, row, column) => {
                    if (row.houseNumber==="") {
                      return {
                        valid: false,
                        message:"Please enter house number."
                      };
                    } 
                    return true;   
                }
            },
            {
                dataField: 'barangay',
                text: 'BARANGAY',
                editable: true,
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'left',width:'10%'}},
                style:{textAlign:'left',width:'10%'},
                validator: (newValue, row, column) => {
                    if (row.streetName==="") {
                      return {
                        valid: false,
                        message:"Please enter street name."
                      };
                    } 
                    return true;   
                }
            },
            {
                dataField: 'postalCode',
                text: 'POSTAL CODE',
                editable: true,
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'center',width:'10%'}},
                style:{textAlign:'center',width:'10%'},
                validator: (newValue, row, column) => {
                    if (this.ValidNumeric(newValue)) {
                      return {
                        valid: false,
                        message:"Value must be numeric."
                      };
                    } 
                    return true;   
                }
            },
            {
                dataField: 'action',
                text: 'ACTION',
                editable:false,
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'center',width:'7%'}
                },
                style:{textAlign:'center',width:'7%'},
                formatter   :   (cell, row, isSelect) => {
                    return (
                        <Button 
                            onClick={e => this.handleRemoveAddress(row.id)}
                            style={{height:'18px',paddingTop:'0',marginTop:'-4px',fontSize:'11px'}} 
                            variant="link"
                    >REMOVE</Button>
                    );
                },
            }
        ]
        const fbCol = [
            {
                dataField: 'roleId',
                text: 'ROLE',
                editable: true,
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'left',width:'10%'}},
                style:{textAlign:'left',width:'10%'},
                formatter: (cell, row) => {
                    if(row.roleId!=='' || row.roleId!==null || typeof row.roleId!=='undefined'){
                        if(this.state.roleDDL.filter(x => x.value == cell).length>0)
                            return this.state.roleDDL.find(x => x.value == cell).label;
                    }
                },
                editor: {
                    type: Type.SELECT,
                    options: this.state.roleDDL
                },
                validator: (newValue, row, column) => {
                    if(this.state.backgroundGRID.filter(x=>x.roleId===newValue).length>0 && newValue==="0")
                    return {
                        valid: false,
                        message:"Cannot set multiple for role father."
                      };
                    if(this.state.backgroundGRID.filter(x=>x.roleId===newValue).length>0 && newValue==="1")
                    return {
                        valid: false,
                        message:"Cannot set multiple for role mother."
                    };
                    if(this.state.backgroundGRID.filter(x=>x.roleId===newValue).length>0 && newValue==="2")
                    return {
                        valid: false,
                        message:"Cannot set multiple for role spouse."
                    };
                    return true;   
                }
            },
            {
                dataField: 'name',
                text: 'Name',
                editable: true,
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'left',width:'20%'}},
                style:{textAlign:'left',width:'20%'},
                validator: (newValue, row, column) => {
                    if (row.roleId==="") {
                      return {
                        valid: false,
                        message:"Please enter role."
                      };
                    } 
                    return true;   
                }
            },
            {
                dataField: 'age',
                text: 'AGE',
                editable: true,
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'center',width:'10%'}},
                style:{textAlign:'center',width:'10%'},
                validator: (newValue, row, column) => {
                    if (row.name==="") {
                        return {
                          valid: false,
                          message:"Please enter name."
                        };
                    } 
                    if (this.ValidNumeric(newValue)) {
                      return {
                        valid: false,
                        message:"Value must be numeric."
                      };
                    } 
                    return true;   
                }
            },
            {
                dataField: 'occupation',
                text: 'OCCUPATION',
                editable: true,
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'center',width:'15%'}},
                style:{textAlign:'center',width:'15%'},
                validator: (newValue, row, column) => {
                    if (row.age==="") {
                        return {
                          valid: false,
                          message:"Please enter age."
                        };
                    } 
                    return true;   
                }
            },
            {
                dataField: 'company',
                text: 'COMPANY',
                editable: true,
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'left',width:'38%'}},
                style:{textAlign:'left',width:'38%'},
                validator: (newValue, row, column) => {
                    if (row.occupation==="") {
                        return {
                          valid: false,
                          message:"Please enter company."
                        };
                    } 
                    return true;   
                }
            },
            {
                dataField: 'action',
                text: 'ACTION',
                editable:false,
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'center',width:'7%'}
                },
                style:{textAlign:'center',width:'7%'},
                formatter   :   (cell, row, isSelect) => {
                    return (
                        <Button 
                        onClick={e=>this.handleClickBackgroundRemove(row.id)}
                            style={{height:'18px',paddingTop:'0',marginTop:'-4px',fontSize:'11px'}} 
                            variant="link"
                    >REMOVE</Button>
                    );
                },
            }
        ]
        const eduCol = [
            {
                dataField: 'levelId',
                text: 'LEVEL',
                editable: true,
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'left',width:'10%'}},
                style:{textAlign:'left',width:'10%'},
                formatter: (cell, row) => {
                    if(row.levelId!=='' || row.levelId!==null || typeof row.levelId!=='undefined'){
                        if(this.state.levelDDL.filter(x => x.value == cell).length>0)
                            return this.state.levelDDL.find(x => x.value == cell).label;
                    }
                },
                editor: {
                    type: Type.SELECT,
                    options: this.state.levelDDL
                },
                validator: (newValue, row, column) => {
                    if(this.state.educationGRID.filter(x=>x.levelId===newValue).length>0)
                    return {
                        valid: false,
                        message:"Selected level already exist."
                      };
                    return true;   
                }
            },
            {
                dataField: 'schoolName',
                text: 'SCHOOL NAME',
                editable: true,
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'left',width:'20%'}},
                style:{textAlign:'left',width:'20%'},
                validator: (newValue, row, column) => {
                    if(row.levelId==="")
                    return {
                        valid: false,
                        message:"Please select school level."
                      };
                    return true;   
                }
            },
            {
                dataField: 'course',
                text: 'COURSE',
                editable: true,
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'left',width:'15%'}},
                style:{textAlign:'left',width:'15%'},
                validator: (newValue, row, column) => {
                    if(row.schoolName==="")
                    return {
                        valid: false,
                        message:"Please enter school name."
                      };
                    return true;   
                }
            },
            {
                dataField: 'startYear',
                text: 'START YEAR',
                editable: true,
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'center',width:'10%'}},
                style:{textAlign:'center',width:'10%'},
                validator: (newValue, row, column) => {
                    if(row.course==="" && row.levelid==="0")
                        return {
                            valid: false,
                            message:"Please enter course."
                    };
                    if (this.ValidNumeric(newValue)) {
                      return {
                        valid: false,
                        message:"Value must be numeric."
                      };
                    } 
                    return true;   
                }
            },
            {
                dataField: 'endYear',
                text: 'END YEAR',
                editable: true,
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'left',width:'10%'}},
                style:{textAlign:'left',width:'10%'},
                validator: (newValue, row, column) => {
                    if(row.startYear==="")
                        return {
                        valid: false,
                        message:"Please enter start year."
                    };
                    if (this.ValidNumeric(newValue)) {
                      return {
                        valid: false,
                        message:"Value must be numeric."
                      };
                    } 
                    return true;   
                }
            },
            {
                dataField: 'honorRecieved',
                text: 'HONOR',
                editable: true,
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'left',width:'28%'}},
                style:{textAlign:'left',width:'28%'},
                validator: (newValue, row, column) => {
                    if(row.endYear==="")
                        return {
                        valid: false,
                        message:"Please enter start year."
                    };
                    return true;   
                }
            },
            {
                dataField: 'action',
                text: 'ACTION',
                editable:false,
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'center',width:'7%'}
                },
                style:{textAlign:'center',width:'7%'},
                formatter   :   (cell, row, isSelect) => {
                    return (
                        <Button 
                            onClick={e=>this.handleRemoveEducation(row.id)}
                            style={{height:'18px',paddingTop:'0',marginTop:'-4px',fontSize:'11px'}} 
                            variant="link"
                    >REMOVE</Button>
                    );
                },
            }
        ]
        const employmentCol =[
            {
                dataField: 'companyName',
                text: 'COMPANY',
                editable: false,
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'left',width:'27%'}},
                style:{textAlign:'left',width:'27%'},
            },
            {
                dataField: 'branchName',
                text: 'BRANCH',
                editable: false,
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'left',width:'25%'}},
                style:{textAlign:'left',width:'25%'},
            },
            {
                dataField: 'position',
                text: 'POSITION',
                editable: false,
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'left',width:'18%'}},
                style:{textAlign:'left',width:'18%'},
            },
            {
                dataField: 'salaryRate',
                text: 'RATE',
                editable: false,
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'center',width:'8%'}},
                style:{textAlign:'right',width:'8%'},
            },
            {
                dataField: 'periodCovered',
                text: 'PERIOD COVERED',
                editable: false,
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'center',width:'15%'}},
                style:{textAlign:'center',width:'15%'},
            },
            // {
            //     dataField: 'reasonForLeaving',
            //     text: 'REASON',
            //     editable: false,
            //     headerStyle: (colum, colIndex) => {
            //         return { textAlign: 'left',width:'27%'}},
            //     style:{textAlign:'left',width:'27%'},
            // },
            {
                dataField: 'action',
                text: 'ACTION',
                editable:false,
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'center',width:'7%'}
                },
                style:{textAlign:'center',width:'7%'},
                formatter   :   (cell, row, isSelect) => {
                    return(<Button 
                            onClick={e => this.handleActivateClick(row)}
                            style={{height:'18px',paddingTop:'0',marginTop:'-4px',fontSize:'11px'}} 
                            variant="link"
                    >{row.statusId==="0" ? "DEACTIVATE" : "ACTIVATE"}</Button>)
                },
                
            }
        ]
        const selectrowEmployment = {
            mode: 'checkbox',
            hideSelectAll: true,
            clickToSelectAndEditCell: true,
            onSelect: (row, isSelect, rowIndex, e) => {
                
            }
        };
        const selectrowAddr = {
            mode: 'checkbox',
            hideSelectAll: true,
            clickToSelectAndEditCell: true,
            onSelect: (row, isSelect, rowIndex, e) => {
                
            }
        };
        const selectrowFB = {
            mode: 'checkbox',
            hideSelectAll: true,
            clickToSelectAndEditCell: true,
            onSelect: (row, isSelect, rowIndex, e) => {
                
            }
        };
        const selectrowEdu = {
            mode: 'checkbox',
            hideSelectAll: true,
            clickToSelectAndEditCell: true,
            onSelect: (row, isSelect, rowIndex, e) => {
                
            }
        };
        return(
            <div>
                <Banner />
                <Container className="themed-container" fluid={true}>
                    <Card className="mt-5">
                        <Card.Header>Manning >> Manage Employee</Card.Header>
                        <Card.Body>
                            <Form >
                                <Alert isOpen={this.state.isshow} color={this.state.color} fade={this.state.fade} className={this.state.isshow ? 'display-block' : 'display-none'}>
                                    <div className={this.state.color}></div> 
                                    {this.state.message}
                                </Alert>
                                <Form.Group as={Row} controlId="formHorizontalEmail">
                                    <Col sm={12}>
                                        <Typeahead
                                            labelKey='name'
                                            id="basic-example"
                                            name="clientId"
                                            onChange={this.handleChangedClient}
                                            options={this.state.clientDDL.filter(x=>x.id!=="111")}
                                            placeholder="Select Client"
                                            selected={[this.state.client]}

                                            // labelKey='name'
                                            // id="basic-example"
                                            // name="clientId"
                                            // onChange={this.handleChangedClient}
                                            // options={this.state.clientDDL}
                                            // placeholder="Select Client"
                                            // selected={[this.state.client]}
                                        />
                                    </Col>
                                </Form.Group>
                                <Form.Group as={Row} controlId="formHorizontalEmail">
                                    <Col sm={12}>
                                        <Typeahead
                                            labelKey='employeeName'
                                            id="basic-example"
                                            onChange={this.handleChangedEmployee}
                                            options={this.state.employeeDDL.filter(x=>x.clientId!=="111")}
                                            placeholder="Select Employee"
                                        />
                                    </Col>
                                </Form.Group>
                            </Form>
                            <Tabs className="mt-2" defaultActiveKey="default" transition={false} id="noanim-tab-example">
                                <Tab eventKey="default" title="Employee Profiles">
                                    <Accordion className="mt-2"  defaultActiveKey="0">
                                        <Card className="card-tab">
                                            <div className="card-header-tab">
                                                <Accordion.Toggle as={Button} variant="link" eventKey="0" style={{color: "#FFFFFF"}}>
                                                        PROFILE INFORMATION
                                                </Accordion.Toggle>
                                            </div>
                                            <Accordion.Collapse eventKey="0">
                                                    <Card.Body>
                                                    <Form >
                                                        <Form.Group as={Row} controlId="formPlaintextEmail">
                                                            <Form.Label column sm="2" style={{fontWeight : "bold"}}>
                                                            STATUS
                                                            </Form.Label>
                                                            <Col sm="3">
                                                                <Form.Control 
                                                                    type="text"
                                                                    id="statusName"
                                                                    name="statusName"
                                                                    value={this.state.profileStatus}
                                                                    disabled
                                                                /> 
                                                            </Col>
                                                            <Form.Label column sm="1" style={{fontWeight : "bold"}}>
                                                            HMO
                                                            </Form.Label>
                                                            <Col sm="1">
                                                                <Typeahead
                                                                    ref="refhmoId"
                                                                    labelKey='name'
                                                                    id='hmoId'
                                                                    name='hmoId'
                                                                    onChange={this.handleChangedHMO}
                                                                    options={this.state.hmoDDL}
                                                                    selected={[this.state.hmo]}
                                                                    disabled={this.state.disableLibrarian}
                                                                />
                                                            </Col>
                                                            <Form.Label column sm="2" style={{fontWeight : "bold"}}>
                                                            HMO DATE
                                                            </Form.Label>
                                                            <Col sm="3">
                                                                <DatePicker
                                                                    id="hmoDate"
                                                                    name="hmoDate"
                                                                    value={this.state.hmoDate}
                                                                    selected={this.state.hmoDate}
                                                                    onChange={this.handleChangedHMODate}
                                                                    minDate={this.minDate}
                                                                    dateFormat={"MM/dd/yyyy"}
                                                                    className="form-control"
                                                                    autoComplete="off"
                                                                    disabled={this.state.disableLibrarian}
                                                                />
                                                            </Col>
                                                        </Form.Group>
                                                        <Form.Group as={Row} controlId="formPlaintextEmail">
                                                            <Form.Label column sm="2" style={{fontWeight : "bold"}}>
                                                            CARD NUMBER
                                                            </Form.Label>
                                                            <Col sm="3">
                                                                <Form.Control 
                                                                    type="text"
                                                                    id="cardNumberHmo"
                                                                    name="cardNumberHmo"
                                                                    value={this.state.cardNumberHmo}
                                                                    onChange={this.handleChangedCardNumber}
                                                                    autoComplete="off"
                                                                    maxLength="16"
                                                                    disabled={this.state.disableWithHmmo}
                                                                    //onKeyPress={this.IsNumeric.bind(this)}
                                                                    maxLength='16'
                                                                />
                                                            </Col>
                                                            <Col sm="2">
                                                            </Col>
                                                            <Form.Label column sm="2" style={{fontWeight : "bold"}}>
                                                            POLICY NUMBER
                                                            </Form.Label>
                                                            <Col sm="3">
                                                                <Form.Control 
                                                                    type="text"
                                                                    id="policyNumber"
                                                                    name="policyNumber"
                                                                    value={this.state.policyNumber}
                                                                    onChange={this.handleChangedPolicyNumber}
                                                                    autoComplete="off"
                                                                    disabled={this.state.disableWithHmmo}
                                                                    //onKeyPress={this.IsNumeric.bind(this)}
                                                                />
                                                            </Col>
                                                        </Form.Group>
                                                        <Form.Group as={Row} controlId="formPlaintextEmail">
                                                            <Form.Label column sm="2" style={{fontWeight : "bold"}}>
                                                            HMO PROVIDER
                                                            </Form.Label>
                                                            <Col sm="3">
                                                                <Typeahead
                                                                    ref="refassuranceId"
                                                                    labelKey='name'
                                                                    id='assuranceId'
                                                                    name='assurance'
                                                                    onChange={this.handleChangedAssurance}
                                                                    options={this.state.assuranceDDL}
                                                                    selected={[this.state.assurance]}
                                                                    disabled={this.state.disableWithHmmo}
                                                                />
                                                            </Col>
                                                            <Col sm="3">
                                                                <Form.Check type="checkbox" 
                                                                    label="WITH ATD" 
                                                                    id="isWithAtd"
                                                                    name="isWithAtd"
                                                                    onChange={this.handleChangedWithAtd}
                                                                    style={{fontWeight : "bold",marginLeft:"2px"}}
                                                                    checked = { this.state.isWithAtd } 
                                                                    //checked={this.state.isWithAtd == "1" ? true:false}
                                                                    disabled={this.state.disableWithHmmo}
                                                                />
                                                            </Col>
                                                        </Form.Group>
                                                        <Form.Group as={Row} controlId="formPlaintextEmail">
                                                            <Form.Label column sm="2" style={{fontWeight : "bold"}}>
                                                            FIRST NAME
                                                            </Form.Label>
                                                            <Col sm="3">
                                                                <Form.Control 
                                                                    type="text"
                                                                    id="firstName"
                                                                    name="firstName"
                                                                    value={this.state.firstName}
                                                                    onChange={this.handleChanged}
                                                                    autoComplete="off"
                                                                    disabled={this.state.disableLibrarian}
                                                                /> 
                                                            </Col>
                                                            <Col sm="2">
                                                            </Col>
                                                            <Form.Label column sm="2" style={{fontWeight : "bold"}}>
                                                            MIDDLE NAME
                                                            </Form.Label>
                                                            <Col sm="3">
                                                                <Form.Control 
                                                                    type="text"
                                                                    id="middleName"
                                                                    name="middleName"
                                                                    value={this.state.middleName}
                                                                    onChange={this.handleChanged}
                                                                    autoComplete="off"
                                                                    disabled={this.state.disableLibrarian}
                                                                />
                                                            </Col>
                                                        </Form.Group>
                                                        <Form.Group as={Row} controlId="formPlaintextEmail">
                                                        <Form.Label column sm="2" style={{fontWeight : "bold"}}>
                                                            LAST NAME
                                                            </Form.Label>
                                                            <Col sm="3">
                                                                <Form.Control 
                                                                    type="text"
                                                                    id="lastName"
                                                                    name="lastName"
                                                                    value={this.state.lastName}
                                                                    onChange={this.handleChanged}
                                                                    autoComplete="off"
                                                                    disabled={this.state.disableLibrarian}
                                                                />
                                                            </Col>
                                                            <Form.Label column sm="1" style={{fontWeight : "bold"}}>
                                                            SUFFIX NAME
                                                            </Form.Label>
                                                            <Col sm="1">
                                                                {/* <Form.Control 
                                                                    type="text"
                                                                    id="suffixName"
                                                                    name="suffixName"
                                                                    value={this.state.suffixName}
                                                                    onChange={this.handleChanged}
                                                                    autoComplete="off"
                                                                    disabled={this.state.disableLibrarian}
                                                                /> */}
                                                                <Form.Control
                                                                    as="select"
                                                                    onChange={this.handleChangedSuffixName}
                                                                    value={this.state.suffixName}
                                                                    disabled={this.state.disableLibrarian}
                                                                >
                                                                {this.state.suffixDDL.map(item => (
                                                                    <option key={item.value} value={item.value}>
                                                                    {item.name}
                                                                    </option>
                                                                ))}
                                                                </Form.Control>
                                                            </Col>
                                                            <Form.Label column sm="2" style={{fontWeight : "bold"}}>
                                                            NICK NAME
                                                            </Form.Label>
                                                            <Col sm="3">
                                                                <Form.Control 
                                                                    type="text"
                                                                    id="nickName"
                                                                    name="nickName"
                                                                    value={this.state.nickName}
                                                                    onChange={this.handleChanged}
                                                                    autoComplete="off"
                                                                    disabled={this.state.disableLibrarian}
                                                                />
                                                            </Col>
                                                        </Form.Group>
                                                        <Form.Group as={Row} controlId="formPlaintextEmail">
                                                            <Form.Label column sm="2" style={{fontWeight : "bold"}}>
                                                            HOME PHONE NUMBER
                                                            </Form.Label>
                                                            <Col sm="3">
                                                                <Form.Control 
                                                                    type="text"
                                                                    id="homeNumber"
                                                                    name="homeNumber"
                                                                    value={this.state.homeNumber}
                                                                    onChange={this.handleChanged}
                                                                    autoComplete="off"
                                                                    disabled={this.state.disableLibrarian}
                                                                />
                                                            </Col>
                                                            <Col sm="2">
                                                            </Col>
                                                            <Form.Label column sm="2" style={{fontWeight : "bold"}}>
                                                            OFFICE PHONE NUMBER
                                                            </Form.Label>
                                                            <Col sm="3">
                                                                <Form.Control 
                                                                    type="text"
                                                                    id="officeNumber"
                                                                    name="officeNumber"
                                                                    value={this.state.officeNumber}
                                                                    onChange={this.handleChanged}
                                                                    autoComplete="off"
                                                                    disabled={this.state.disableLibrarian}
                                                                />
                                                            </Col>
                                                        </Form.Group>
                                                        
                                                        <Form.Group as={Row} controlId="formPlaintextEmail">
                                                            <Form.Label column sm="2" style={{fontWeight : "bold"}}>
                                                            FACEBOOK
                                                            </Form.Label>
                                                            <Col sm="3">
                                                                <Form.Control 
                                                                    type="text"
                                                                    id="facebook"
                                                                    name="facebook"
                                                                    value={this.state.facebook}
                                                                    onChange={this.handleChanged}
                                                                    autoComplete="off"
                                                                    disabled={this.state.disableLibrarian}
                                                                />
                                                            </Col>
                                                            <Col sm="2">
                                                            </Col>
                                                            <Form.Label column sm="2" style={{fontWeight : "bold"}}>
                                                            TWITTER
                                                            </Form.Label>
                                                            <Col sm="3">
                                                                <Form.Control 
                                                                    type="text"
                                                                    id="twitter"
                                                                    name="twitter"
                                                                    value={this.state.twitter}
                                                                    onChange={this.handleChanged}
                                                                    autoComplete="off"
                                                                    disabled={this.state.disableLibrarian}
                                                                />
                                                            </Col>
                                                        </Form.Group>
                                                        <Form.Group as={Row} controlId="formPlaintextEmail">
                                                            <Form.Label column sm="2" style={{fontWeight : "bold"}}>
                                                            NATIONALITY
                                                            </Form.Label>
                                                            <Col sm="3">
                                                                <Typeahead
                                                                    labelKey='name'
                                                                    id='nationality'
                                                                    name='nationality'
                                                                    //onChange={e => this.handleChangedDDL('nationalityId')}
                                                                    onChange={this.handleChangedNationality}
                                                                    options={this.state.nationalityDDL}
                                                                    selected={[this.state.nationality]}
                                                                    disabled={this.state.disableLibrarian}
                                                                />
                                                            </Col>
                                                            <Col sm="2">
                                                            </Col>
                                                            <Form.Label column sm="2" style={{fontWeight : "bold"}}>
                                                            RELIGION
                                                            </Form.Label>
                                                            <Col sm="3">
                                                                <Typeahead
                                                                    labelKey='name'
                                                                    id='religionId'
                                                                    name='religionId'
                                                                    //onChange={e => this.handleChangedDDL('religionId')}
                                                                    onChange={this.handleChangedReligion}
                                                                    options={this.state.religionDDL}
                                                                    selected={[this.state.religion]}
                                                                    disabled={this.state.disableLibrarian}
                                                                />
                                                            </Col>
                                                        </Form.Group>
                                                        <Form.Group as={Row} controlId="formPlaintextEmail">
                                                            <Form.Label column sm="2" style={{fontWeight : "bold"}}>
                                                            GENDER
                                                            </Form.Label>
                                                            <Col sm="3">
                                                                <Typeahead
                                                                    labelKey='name'
                                                                    name="genderId"
                                                                    id="genderId"
                                                                    //onChange={this.handleChangedDDL("genderId")}
                                                                    onChange={this.handleChangedGender}
                                                                    options={this.state.genderDDL}
                                                                    selected={[this.state.gender]}
                                                                    disabled={this.state.disableLibrarian}
                                                                />
                                                            </Col>
                                                            <Col sm="2">
                                                            </Col>
                                                            <Form.Label column sm="2" style={{fontWeight : "bold"}}>
                                                            EMAIL ADDRESS
                                                            </Form.Label>
                                                            <Col sm="3">
                                                                <Form.Control 
                                                                    type="text"
                                                                    id="emailAddress"
                                                                    name="emailAddress"
                                                                    value={this.state.emailAddress}
                                                                    onChange={this.handleChanged}
                                                                    autoComplete="off"
                                                                    disabled={this.state.disableLibrarian}
                                                                />
                                                            </Col>
                                                        </Form.Group>
                                                        <Form.Group as={Row} controlId="formPlaintextEmail">
                                                            <Form.Label column sm="2" style={{fontWeight : "bold"}}>
                                                            DATE OF BIRTH
                                                            </Form.Label>
                                                            <Col sm="3">
                                                                <DatePicker
                                                                    id="dateofBirth"
                                                                    name="dateofBirth"
                                                                    value={this.state.dateofBirth}
                                                                    selected={this.state.dateofBirth}
                                                                    onChange={this.handleChangedDOB}
                                                                    minDate={this.minDate}
                                                                    dateFormat={"MM/dd/yyyy"}
                                                                    className="form-control"
                                                                    autoComplete="off"
                                                                    disabled={this.state.disableLibrarian}
                                                                />
                                                            </Col>
                                                            <Col sm="2">
                                                            </Col>
                                                            <Form.Label column sm="2" style={{fontWeight : "bold"}}>
                                                            PLACE OF BIRTH
                                                            </Form.Label>
                                                            <Col sm="3">
                                                                <Form.Control 
                                                                    type="text"
                                                                    id="placeofBirth"
                                                                    name="placeofBirth"
                                                                    value={this.state.placeofBirth}
                                                                    onChange={this.handleChanged} 
                                                                    autoComplete="off"
                                                                    disabled={this.state.disableLibrarian}
                                                                />
                                                            </Col>
                                                        </Form.Group>
                                                        <Form.Group as={Row} controlId="formPlaintextEmail">
                                                            <Form.Label column sm="2" style={{fontWeight : "bold"}}>
                                                            LIVING ARRANGEMENT
                                                            </Form.Label>
                                                            <Col sm="3">
                                                                <Typeahead
                                                                    labelKey='name'
                                                                    id='arrangementId'
                                                                    name='arrangementId'
                                                                    onChange={this.handleChangedArrangement}
                                                                    options={this.state.arrangementDDL}
                                                                    selected={[this.state.arrangement]}
                                                                    disabled={this.state.disableLibrarian}
                                                                />
                                                            </Col>
                                                            <Col sm="2">
                                                            </Col>
                                                            <Form.Label column sm="2" style={{fontWeight : "bold"}}>
                                                            BLOOD TYPE
                                                            </Form.Label>
                                                            <Col sm="3">
                                                                <Typeahead
                                                                    labelKey='name'
                                                                    id='bloodtypeId'
                                                                    name='bloodtypeId'
                                                                    onChange={this.handleChangedBloodType}
                                                                    options={this.state.bloodtypeDDL}
                                                                    selected={[this.state.bloodtype]}
                                                                    disabled={this.state.disableLibrarian}
                                                                />
                                                            </Col>
                                                        </Form.Group>
                                                        <Form.Group as={Row} controlId="formPlaintextEmail">
                                                            <Form.Label column sm="2" style={{fontWeight : "bold"}}>
                                                            HEIGHT
                                                            </Form.Label>
                                                            <Col sm="3">
                                                                <Form.Control 
                                                                    type="text"
                                                                    id="height"
                                                                    name="height"
                                                                    value={this.state.height}
                                                                    autoComplete="off"
                                                                    onChange={this.handleChanged}
                                                                    disabled={this.state.disableLibrarian}
                                                                />
                                                            </Col>
                                                            <Col sm="2">
                                                            </Col>
                                                            <Form.Label column sm="2" style={{fontWeight : "bold"}}>
                                                            WEIGHT
                                                            </Form.Label>
                                                            <Col sm="3">
                                                                <Form.Control 
                                                                    type="text"
                                                                    id="weight"
                                                                    name="weight"
                                                                    value={this.state.weight}
                                                                    autoComplete="off"
                                                                    onChange={this.handleChanged}
                                                                    disabled={this.state.disableLibrarian}
                                                                />
                                                            </Col>
                                                        </Form.Group>
                                                        <Form.Group as={Row} controlId="formPlaintextEmail">
                                                            <Form.Label column sm="2" style={{fontWeight : "bold"}}>
                                                            LANGUAGE SPOKEN
                                                            </Form.Label>
                                                            <Col sm="3">
                                                                <Form.Control 
                                                                    type="text"
                                                                    id="languageSpoken"
                                                                    name="languageSpoken"
                                                                    value={this.state.languageSpoken}
                                                                    onChange={this.handleChanged}
                                                                    autoComplete="off"
                                                                    disabled={this.state.disableLibrarian}
                                                                />
                                                            </Col>
                                                            <Col sm="2">
                                                            </Col>
                                                            <Form.Label column sm="2" style={{fontWeight : "bold"}}>
                                                            HOBBIES
                                                            </Form.Label>
                                                            <Col sm="3">
                                                                <Form.Control 
                                                                    type="text"
                                                                    id="hobbies"
                                                                    name="hobbies"
                                                                    onChange={this.handleChanged}
                                                                    value={this.state.hobbies}
                                                                    autoComplete="off"
                                                                    disabled={this.state.disableLibrarian}
                                                                />
                                                            </Col>
                                                        </Form.Group>
                                                        <Form.Group as={Row} controlId="formPlaintextEmail">
                                                            <Form.Label column sm="2" style={{fontWeight : "bold"}}>
                                                            EMERGENCY CONTACT NAME
                                                            </Form.Label>
                                                            <Col sm="3">
                                                                <Form.Control 
                                                                    type="text"
                                                                    id="contactName"
                                                                    name="contactName"
                                                                    value={this.state.contactName}
                                                                    onChange={this.handleChanged}
                                                                    autoComplete="off"
                                                                    disabled={this.state.disableLibrarian}
                                                                />
                                                            </Col>
                                                            <Col sm="2">
                                                            </Col>
                                                            <Form.Label column sm="2" style={{fontWeight : "bold"}}>
                                                            EMERGENCY CONTACT NUMBER
                                                            </Form.Label>
                                                            <Col sm="3">
                                                                <Form.Control 
                                                                    type="text"
                                                                    id="contactNumber"
                                                                    name="contactNumber"
                                                                    value={this.state.contactNumber}
                                                                    onChange={this.handleChanged}
                                                                    autoComplete="off"
                                                                    disabled={this.state.disableLibrarian}
                                                                />
                                                            </Col>
                                                        </Form.Group>
                                                        <Form.Group as={Row} controlId="formPlaintextEmail">
                                                            <Form.Label column sm="2" style={{fontWeight : "bold"}}>
                                                            EMERGENCY CONTACT RELATION
                                                            </Form.Label>
                                                            <Col sm="3">
                                                                <Form.Control 
                                                                    type="text"
                                                                    id="contactRelation"
                                                                    name="contactRelation"
                                                                    value={this.state.contactRelation}
                                                                    onChange={this.handleChanged}
                                                                    autoComplete="off"
                                                                    disabled={this.state.disableLibrarian}
                                                                />
                                                            </Col>
                                                            <Col sm="2">
                                                            </Col>
                                                        </Form.Group>
                                                        <Form.Group as={Row} controlId="formPlaintextEmail">
                                                            <Form.Label column sm="1" style={{fontWeight : "bold"}}>
                                                            SSS NUMBER
                                                            </Form.Label>
                                                            <Col sm="2">
                                                                <NumberFormat
                                                                    id="sssNumber"
                                                                    name="sssNumber"
                                                                    value={this.state.sssNumber}
                                                                    onChange={this.handleChanged} 
                                                                    autoComplete="off"
                                                                    format="##-#######-#"
                                                                    className="form-control"
                                                                    disabled={this.state.disableLibrarian}
                                                                />
                                                            </Col>
                                                            <Form.Label column sm="1" style={{fontWeight : "bold"}}>
                                                            REMARKS
                                                            </Form.Label>
                                                            <Col sm="2">
                                                                <Form.Control 
                                                                    type="text"
                                                                    id="sssNumberRemarks"
                                                                    name="sssNumberRemarks"
                                                                    value={this.state.sssNumberRemarks}
                                                                    onChange={this.handleChanged}
                                                                    autoComplete="off"
                                                                    maxLength="2000"
                                                                    disabled={this.state.disableLibrarian}
                                                                />
                                                            </Col>
                                                            <Form.Label column sm="1" style={{fontWeight : "bold"}}>
                                                            TIN NUMBER 
                                                            </Form.Label>
                                                            <Col sm="2">
                                                                <NumberFormat
                                                                    id="tinNumber"
                                                                    name="tinNumber"
                                                                    value={this.state.tinNumber}
                                                                    onChange={this.handleChanged} 
                                                                    autoComplete="off"
                                                                    format="###-###-###-###"
                                                                    className="form-control"
                                                                    disabled={this.state.disableLibrarian}
                                                                />
                                                            </Col>
                                                            <Form.Label column sm="1" style={{fontWeight : "bold"}}>
                                                            REMARKS
                                                            </Form.Label>
                                                            <Col sm="2">
                                                                <Form.Control 
                                                                    type="text"
                                                                    id="tinNumberRemarks"
                                                                    name="tinNumberRemarks"
                                                                    value={this.state.tinNumberRemarks}
                                                                    onChange={this.handleChanged}
                                                                    autoComplete="off"
                                                                    maxLength="2000"
                                                                    disabled={this.state.disableLibrarian}
                                                                />
                                                            </Col>
                                                        </Form.Group>
                                                        <Form.Group as={Row} controlId="formPlaintextEmail">
                                                            <Form.Label column sm="1" style={{fontWeight : "bold"}}>
                                                            PHIC NUMBER
                                                            </Form.Label>
                                                            <Col sm="2">
                                                                <NumberFormat
                                                                    id="phicNumber"
                                                                    name="phicNumber"
                                                                    value={this.state.phicNumber}
                                                                    onChange={this.handleChanged} 
                                                                    autoComplete="off"
                                                                    format="##-#########-#"
                                                                    className="form-control"
                                                                    disabled={this.state.disableLibrarian}
                                                                />
                                                            </Col>
                                                            <Form.Label column sm="1" style={{fontWeight : "bold"}}>
                                                            REMARKS
                                                            </Form.Label>
                                                            <Col sm="2">
                                                                <Form.Control 
                                                                    type="text"
                                                                    id="phicNumberRemarks"
                                                                    name="phicNumberRemarks"
                                                                    value={this.state.phicNumberRemarks}
                                                                    onChange={this.handleChanged}
                                                                    autoComplete="off"
                                                                    maxLength="2000"
                                                                    disabled={this.state.disableLibrarian}
                                                                />
                                                            </Col>
                                                            <Form.Label column sm="1" style={{fontWeight : "bold"}}>
                                                            HDMF NUMBER 
                                                            </Form.Label>
                                                            <Col sm="2">
                                                                <NumberFormat
                                                                    id="hdmfNumber"
                                                                    name="hdmfNumber"
                                                                    value={this.state.hdmfNumber}
                                                                    onChange={this.handleChanged} 
                                                                    autoComplete="off"
                                                                    format="####-####-####"
                                                                    className="form-control"
                                                                    disabled={this.state.disableLibrarian}
                                                                />
                                                            </Col>
                                                            <Form.Label column sm="1" style={{fontWeight : "bold"}}>
                                                            REMARKS
                                                            </Form.Label>
                                                            <Col sm="2">
                                                                <Form.Control 
                                                                    type="text"
                                                                    id="hdmfNumberRemarks"
                                                                    name="hdmfNumberRemarks"
                                                                    value={this.state.hdmfNumberRemarks}
                                                                    onChange={this.handleChanged}
                                                                    autoComplete="off"
                                                                    maxLength="2000"
                                                                    disabled={this.state.disableLibrarian}
                                                                />
                                                            </Col>
                                                        </Form.Group>
                                                        <Form.Group as={Row} controlId="formPlaintextEmail">
                                                            <Form.Label column sm="1" style={{fontWeight : "bold"}}>
                                                            MOBILE NUMBER
                                                            </Form.Label>
                                                            <Col sm="2">
                                                                <Form.Control 
                                                                    type="text"
                                                                    id="mobileNumber"
                                                                    name="mobileNumber"
                                                                    value={this.state.mobileNumber}
                                                                    onChange={this.handleChanged}
                                                                    autoComplete="off"
                                                                    disabled={this.state.disableLibrarian}
                                                                />
                                                            </Col>
                                                            <Form.Label column sm="1" style={{fontWeight : "bold"}}>
                                                            EFFECTIVITY DATE
                                                            </Form.Label>
                                                            <Col sm="2">
                                                                <DatePicker
                                                                    id='mobileNumberEffectivityDate'
                                                                    name='mobileNumberEffectivityDate'
                                                                    onChange={this.handleChangedDate('mobileNumberEffectivityDate')}
                                                                    value={this.state.mobileNumberEffectivityDate}
                                                                    selected={this.state.mobileNumberEffectivityDate}
                                                                    dateFormat={"MM/dd/yyyy"}
                                                                    className="form-control"
                                                                    placeholder="Effectivity Date"
                                                                    minDate={this.state.effectivityMinDate}
                                                                    autoComplete="off"
                                                                    disabled={this.state.disableLibrarian}
                                                                />
                                                            </Col>
                                                            <Form.Label column sm="1" style={{fontWeight : "bold"}}>
                                                            CIVIL STATUS
                                                            </Form.Label>
                                                            <Col sm="2">
                                                                <Typeahead
                                                                    labelKey='name'
                                                                    id='civilstatusId'
                                                                    name='civilstatusId'
                                                                    onChange={this.handleChangedCivilStatus}
                                                                    options={this.state.civilstatusDDL}
                                                                    selected={[this.state.civilstatus]}
                                                                    disabled={this.state.disableLibrarian}
                                                                />
                                                            </Col>
                                                            <Form.Label column sm="1" style={{fontWeight : "bold"}}>
                                                            EFFECTIVITY
                                                            </Form.Label>
                                                            <Col sm="2">
                                                                <DatePicker
                                                                    id='civilStatusEffectivityDate'
                                                                    name='civilStatusEffectivityDate'
                                                                    onChange={this.handleChangedDate('civilStatusEffectivityDate')}
                                                                    value={this.state.civilStatusEffectivityDate}
                                                                    selected={this.state.civilStatusEffectivityDate}
                                                                    dateFormat={"MM/dd/yyyy"}
                                                                    className="form-control"
                                                                    placeholder="Effectivity Date"
                                                                    minDate={this.state.effectivityMinDate}
                                                                    autoComplete="off"
                                                                    disabled={this.state.disableLibrarian}
                                                                />
                                                            </Col>
                                                        </Form.Group>
                                                    </Form>
                                                </Card.Body>
                                            </Accordion.Collapse>
                                        </Card>
                                        <Card>
                                            <div className="card-header-tab">
                                                <Accordion.Toggle as={Button} variant="link" eventKey="1" style={{color: "#FFFFFF"}}>
                                                        EMPLOYEE ADDRESSESS
                                                </Accordion.Toggle>
                                            </div>
                                            <Accordion.Collapse eventKey="1">
                                                <div>
                                                <BootstrapTable
                                                    striped
                                                    hover
                                                    condensed
                                                    keyField = "id"
                                                    data = { this.state.addressGRID }
                                                    columns = { addrCol}
                                                    selectRow = { selectrowAddr }
                                                    cellEdit = { cellEditFactory({ 
                                                        mode: 'dbclick', 
                                                        blurToSave: true,
                                                        afterSaveCell: (oldValue, newValue, row, column) => { 
                                                            this.GridAddress(oldValue, newValue, row.id, column.dataField)
                                                        }})
                                                    }
                                                    rowClasses="noser-table-row-class no-checkbox"
                                                    noDataIndication={ () => <div style={{width:'100%'}}>No record found.</div> }
                                                />
                                                <Button id="addrid"
                                                    className="noser-button" 
                                                    variant="success" 
                                                    onClick = {this.handleAddAddress}
                                                    style={{marginLeft:'2px',marginBottom:'4px'}}
                                                >ADD</Button>
                                                </div> 
                                            </Accordion.Collapse>
                                        </Card>
                                        <Card>
                                            <div className="card-header-tab">
                                                <Accordion.Toggle as={Button} variant="link" eventKey="2" style={{color: "#FFFFFF"}}>
                                                        FAMILY BACKGROUND
                                                </Accordion.Toggle>
                                            </div>
                                            <Accordion.Collapse eventKey="2">
                                                <div>
                                                    <BootstrapTable
                                                        striped
                                                        hover
                                                        condensed
                                                        keyField = "id"
                                                        data = { this.state.backgroundGRID }
                                                        columns = { fbCol}
                                                        selectRow = { selectrowFB }
                                                        cellEdit = { cellEditFactory({ 
                                                            mode: 'dbclick', 
                                                            blurToSave: true,
                                                            afterSaveCell: (oldValue, newValue, row, column) => { 
                                                                this.GridBackground(oldValue, newValue, row.id, column.dataField)
                                                            }})
                                                        }
                                                        rowClasses="noser-table-row-class no-checkbox"
                                                        noDataIndication={ () => <div style={{width:'100%'}}>No record found.</div> }
                                                    />
                                                    <Button id="fbid"
                                                        className="noser-button" 
                                                        variant="success" 
                                                        onClick = {this.handleClickBackground}
                                                        style={{marginLeft:'2px',marginBottom:'4px'}}
                                                    >ADD</Button>
                                                </div> 
                                            </Accordion.Collapse>
                                        </Card>
                                        <Card>
                                            <div className="card-header-tab">
                                                <Accordion.Toggle as={Button} variant="link" eventKey="3" style={{color: "#FFFFFF"}}>
                                                        EDUCATION ATTAINMENT
                                                </Accordion.Toggle>
                                            </div>
                                            <Accordion.Collapse eventKey="3">
                                                <div>
                                                    <BootstrapTable
                                                        striped
                                                        hover
                                                        condensed
                                                        keyField = "id"
                                                        data = { this.state.educationGRID }
                                                        columns = { eduCol}
                                                        selectRow = { selectrowEdu }
                                                        cellEdit = { cellEditFactory({ 
                                                            mode: 'dbclick', 
                                                            blurToSave: true,
                                                            afterSaveCell: (oldValue, newValue, row, column) => { 
                                                                this.GridEducation(oldValue, newValue, row.id, column.dataField)
                                                            }})
                                                        }
                                                        rowClasses="noser-table-row-class no-checkbox"
                                                        noDataIndication={ () => <div style={{width:'100%'}}>No record found.</div> }
                                                    />
                                                    <Button id="eduid"
                                                        className="noser-button" 
                                                        variant="success" 
                                                        onClick = {this.handleAddEducation}
                                                        style={{marginLeft:'2px',marginBottom:'4px'}}
                                                    >ADD</Button>
                                                </div>
                                            </Accordion.Collapse>
                                        </Card>
                                    </Accordion>
                                </Tab>
                                <Tab eventKey="201" title="Employee Records">
                                    <Accordion className="mt-2"  defaultActiveKey="0">
                                    <Card className="card-tab">
                                        <div className="card-header-tab">
                                            <Accordion.Toggle as={Button} variant="link" eventKey="0" style={{color: "#FFFFFF"}}>
                                                EMPLOYEE RECORDS
                                            </Accordion.Toggle>
                                        </div>
                                        <Accordion.Collapse eventKey="0">
                                        <Card.Body>
                                            <Form>
                                                <Form.Group as={Row} controlId="formPlaintextEmail">
                                                    <Form.Label column sm="1" style={{fontWeight : "bold"}}>
                                                        CLIENT
                                                    </Form.Label>
                                                    <Col sm="5">
                                                        <Form.Control 
                                                            type="text" 
                                                            id="clientName"
                                                            name="clientName"
                                                            value={this.state.client}
                                                            disabled
                                                        />
                                                    </Col>
                                                    
                                                    <Form.Label column sm="1" style={{fontWeight : "bold"}}>
                                                    EMPLOYEE NO
                                                    </Form.Label>
                                                    <Col sm="5">
                                                        <Form.Control 
                                                            type="text" 
                                                            id="employeeNumber"
                                                            name="employeeNumber"
                                                            value={this.state.employeeNo}
                                                            disabled
                                                        />
                                                    </Col>
                                                </Form.Group>
                                                <Form.Group as={Row} controlId="formPlaintextEmail">
                                                    <Form.Label column sm="1" style={{fontWeight : "bold"}}>
                                                    BRANCH
                                                    </Form.Label>
                                                    <Col sm="2">
                                                        <Typeahead 
                                                            labelKey='name'
                                                            id='locationId'
                                                            name='locationId'
                                                            onChange={this.handleChangedLocation}
                                                            options={this.state.locationDDL}
                                                            selected={[this.state.location]}
                                                            disabled={this.state.disableLibrarian}
                                                        />
                                                    </Col>
                                                    <Form.Label column sm="1" style={{fontWeight : "bold"}}>
                                                    EFFECTIVITY
                                                    </Form.Label>
                                                    <Col sm="2">
                                                        <DatePicker
                                                            id='locationEffectivityDate'
                                                            name='locationEffectivityDate'
                                                            onChange={this.handleChangedDate('locationEffectivityDate')}
                                                            minDate={this.state.effectivityMinDate}
                                                            value={this.state.locationEffectivityDate}
                                                            selected={this.state.locationEffectivityDate}
                                                            dateFormat={"MM/dd/yyyy"}
                                                            className="form-control"
                                                            placeholder="Effectivity Date"
                                                            autoComplete="off"
                                                            disabled={this.state.disableLibrarian}
                                                        />
                                                    </Col>
                                                    <Col sm="4">
                                                        <Form.Check type="checkbox" 
                                                            label="IS DEFAULT BRANCH" 
                                                            id="isdefaultLocation"
                                                            name="isdefaultLocation"
                                                            onChange={this.handleChangedCB}
                                                            style={{fontWeight : "bold",marginLeft:"2px"}}
                                                            checked={this.state.isdefaultLocation == "1" ? true:false}
                                                            disabled={this.state.disableLibrarian}
                                                        />
                                                    </Col>
                                                </Form.Group>
                                                <Form.Group as={Row} controlId="formPlaintextEmail">
                                                    <Form.Label column sm="1" style={{fontWeight : "bold"}}>
                                                    POSITION
                                                    </Form.Label>
                                                    <Col sm="2">
                                                        <Typeahead
                                                            labelKey='name'
                                                            id='positionId'
                                                            name='positionId'
                                                            onChange={this.handleChangedPosition}
                                                            options={this.state.positionDDL}
                                                            selected={[this.state.position]}
                                                            disabled={this.state.disableLibrarian}
                                                        />
                                                    </Col>
                                                    <Form.Label column sm="1" style={{fontWeight : "bold"}}>
                                                    EFFECTIVITY
                                                    </Form.Label>
                                                    <Col sm="2">
                                                        <DatePicker
                                                            id='positionEffectivityDate'
                                                            name='positionEffectivityDate'
                                                            onChange={this.handleChangedDate('positionEffectivityDate')}
                                                            minDate={this.state.effectivityMinDate}
                                                            value={this.state.positionEffectivityDate}
                                                            selected={this.state.positionEffectivityDate}
                                                            dateFormat={"MM/dd/yyyy"}
                                                            className="form-control"
                                                            placeholder="Effectivity Date"
                                                            autoComplete="off"
                                                            disabled={this.state.disableLibrarian}
                                                        />
                                                    </Col>
                                                    <Form.Label column sm="1" style={{fontWeight : "bold"}}>
                                                    EMPLOYMENT 
                                                    </Form.Label>
                                                    <Col sm="2">
                                                        <Typeahead
                                                            labelKey='name'
                                                            id='employmentId'
                                                            name='employmentId'
                                                            //onChange={e => this.handleChangedDDL('employmentId')}
                                                            onChange={this.handleChangedEmployment}
                                                            options={this.state.employmentDDL}
                                                            selected={[this.state.employment]}
                                                            disabled={this.state.disableLibrarian}
                                                        />
                                                    </Col>
                                                    <Form.Label column sm="1" style={{fontWeight : "bold"}}>
                                                    EFFECTIVITY
                                                    </Form.Label>
                                                    <Col sm="2">
                                                        <DatePicker
                                                            id='employmentStatusEffectivityDate'
                                                            name='employmentStatusEffectivityDate'
                                                            onChange={this.handleChangedDate('employmentStatusEffectivityDate')}
                                                            minDate={this.state.effectivityMinDate}
                                                            value={this.state.employmentStatusEffectivityDate}
                                                            selected={this.state.employmentStatusEffectivityDate}
                                                            dateFormat={"MM/dd/yyyy"}
                                                            className="form-control"
                                                            placeholder="Effectivity Date"
                                                            autoComplete="off"
                                                            disabled={this.state.disableLibrarian}
                                                        />
                                                    </Col>
                                                </Form.Group>
                                                <Form.Group as={Row} controlId="formPlaintextEmail">
                                                    <Form.Label column sm="1" style={{fontWeight : "bold"}}></Form.Label>
                                                    <Col sm="2"></Col>
                                                    <Form.Label column sm="1" style={{fontWeight : "bold"}}></Form.Label>
                                                    <Col sm="2">
                                                        {/* <DatePicker
                                                            id='positionEffectivityDate'
                                                            name='positionEffectivityDate'
                                                            onChange={this.handleChangedDate('positionEffectivityDate')}
                                                            minDate={this.state.effectivityMinDate}
                                                            value={this.state.positionEffectivityDate}
                                                            selected={this.state.positionEffectivityDate}
                                                            dateFormat={"MM/dd/yyyy"}
                                                            className="form-control"
                                                            placeholder="Effectivity Date"
                                                            autoComplete="off"
                                                            disabled={this.state.disableLibrarian}
                                                        /> */}
                                                    </Col>
                                                    {/* 123 */}
                                                    <Form.Label column sm="1" style={{fontWeight : "bold"}}>
                                                    LEAVE DATE START 
                                                    </Form.Label>
                                                    <Col sm="2">
                                                       <DatePicker
                                                            id='employeeDateStart'
                                                            name='employeeDateStart'
                                                            onChange={this.handleChangedDate('employeeDateStart')}
                                                            minDate={this.state.effectivityMinDate}
                                                            value={this.state.employeeDateStart}
                                                            selected={this.state.employeeDateStart}
                                                            dateFormat={"MM/dd/yyyy"}
                                                            className="form-control"
                                                            //placeholder="Date Start  Date"
                                                            autoComplete="off"
                                                            disabled={this.state.disableDateStart}
                                                        />
                                                    </Col>
                                                    <Form.Label column sm="1" style={{fontWeight : "bold"}}>
                                                    LEAVE DATE END
                                                    </Form.Label>
                                                    <Col sm="2">
                                                        <DatePicker
                                                            id='employeeDateEnd'
                                                            name='employeeDateEnd'
                                                            selected={this.state.employeeDateEnd}
                                                            onChange={this.handleChangedDate('employeeDateEnd')}
                                                            minDate={this.minDate}
                                                            value={this.state.employeeDateEnd}
                                                            dateFormat={"MM/dd/yyyy"}
                                                            className="form-control"
                                                            disabled={this.state.disableDateEnd}
                                                            autoComplete="off"
                                                        />
                                                    </Col>
                                                </Form.Group>
                                                <Form.Group as={Row} controlId="formPlaintextEmail">
                                                    <Form.Label column sm="1" style={{fontWeight : "bold"}}></Form.Label>
                                                    <Col sm="2"></Col>
                                                    <Form.Label column sm="1" style={{fontWeight : "bold"}}></Form.Label>
                                                    <Col sm="2">
                                                        {/* <DatePicker
                                                            id='positionEffectivityDate'
                                                            name='positionEffectivityDate'
                                                            onChange={this.handleChangedDate('positionEffectivityDate')}
                                                            minDate={this.state.effectivityMinDate}
                                                            value={this.state.positionEffectivityDate}
                                                            selected={this.state.positionEffectivityDate}
                                                            dateFormat={"MM/dd/yyyy"}
                                                            className="form-control"
                                                            placeholder="Effectivity Date"
                                                            autoComplete="off"
                                                            disabled={this.state.disableLibrarian}
                                                        /> */}
                                                    </Col>
                                                    {/* 123 */}
                                                    <Form.Label column sm="1" style={{fontWeight : "bold"}}>
                                                     EDD 
                                                    </Form.Label>
                                                    <Col sm="2">
                                                       <DatePicker
                                                            id='eddDate'
                                                            name='eddDate'
                                                            onChange={this.handleChangedDate('eddDate')}
                                                            minDate={this.state.effectivityMinDate}
                                                            value={this.state.eddDate}
                                                            selected={this.state.eddDate}
                                                            dateFormat={"MM/dd/yyyy"}
                                                            className="form-control"
                                                            //placeholder="Date Start  Date"
                                                            autoComplete="off"
                                                            disabled={this.state.disableEDD}
                                                        />
                                                    </Col>
                                                    <Form.Label column sm="1" style={{fontWeight : "bold"}}>
                                                    
                                                    </Form.Label>
                                                    <Col sm="2">
                                                    
                                                    </Col>
                                                </Form.Group>
                                                <Form.Group as={Row} controlId="formPlaintextEmail">
                                                    <Form.Label column sm="1" style={{fontWeight : "bold"}}>
                                                    DATE HIRED
                                                    </Form.Label>
                                                    <Col sm="2">
                                                        <DatePicker
                                                            id='dateHired'
                                                            name='dateHired'
                                                            onChange={this.handleChangedDate('dateHired')}
                                                            minDate={this.minDate}
                                                            value={this.state.dateHired}
                                                            selected={this.state.dateHired}
                                                            dateFormat={"MM/dd/yyyy"}
                                                            className="form-control"
                                                            autoComplete="off"
                                                            //disabled
                                                        />
                                                    </Col>
                                                    <Form.Label column sm="1" style={{fontWeight : "bold"}}>
                                                    EFFECTIVITY
                                                    </Form.Label>
                                                    <Col sm="2">
                                                        <DatePicker
                                                            id='dateHired'
                                                            name='dateHired'
                                                            //onChange={this.handleChangedDate('dateHired')}
                                                            minDate={this.minDate}
                                                            value={this.state.dateHired}
                                                            //selected={this.state.dateHired}
                                                            dateFormat={"MM/dd/yyyy"}
                                                            className="form-control"
                                                            placeholder="Effectivity Date"
                                                            autoComplete="off"
                                                            disabled
                                                        />
                                                    </Col>
                                                    <Form.Label column sm="1" style={{fontWeight : "bold"}}>
                                                    LAST WORKING DATE
                                                    </Form.Label>
                                                    <Col sm="2">
                                                        <DatePicker
                                                            id='dateResigned'
                                                            name='dateResigned'
                                                            selected={this.state.dateResigned}
                                                            onChange={this.handleChangedDate('dateResigned')}
                                                            minDate={this.minDate}
                                                            value={this.state.dateResigned}
                                                            dateFormat={"MM/dd/yyyy"}
                                                            className="form-control"
                                                            disabled={this.state.disableresigned}
                                                            autoComplete="off"
                                                            
                                                        />
                                                    </Col>
                                                    <Form.Label column sm="1" style={{fontWeight : "bold"}}>
                                                    EFFECTIVITY
                                                    </Form.Label>
                                                    <Col sm="2">
                                                        <DatePicker
                                                            id='dateResignedEffectivityDate'
                                                            name='dateResignedEffectivityDate'
                                                            onChange={this.handleChangedDate('dateResignedEffectivityDate')}
                                                            minDate={this.state.effectivityMinDate}
                                                            value={this.state.dateResignedEffectivityDate}
                                                            selected={this.state.dateResignedEffectivityDate}
                                                            dateFormat={"MM/dd/yyyy"}
                                                            className="form-control"
                                                            placeholder="Effectivity Date"
                                                            autoComplete="off"
                                                            disabled={this.state.disableLibrarian}
                                                        />
                                                    </Col>
                                                </Form.Group>
                                                <Form.Group as={Row} controlId="formPlaintextEmail">
                                                    <Form.Label column sm="1" style={{fontWeight : "bold"}}>
                                                    CONTRACT START
                                                    </Form.Label>
                                                    <Col sm="2">
                                                        <DatePicker
                                                            id='contractStart'
                                                            name='contractStart'
                                                            minDate={this.minDate}
                                                            value={this.state.contractStart}
                                                            selected={this.state.contractStart}
                                                            dateFormat={"MM/dd/yyyy"}
                                                            className="form-control"
                                                            onChange={this.handleChangedDate('contractStart')}
                                                        />
                                                    </Col>
                                                    <Form.Label column sm="1" style={{fontWeight : "bold"}}>
                                                    EFFECTIVITY
                                                    </Form.Label>
                                                    <Col sm="2">
                                                        <DatePicker
                                                            id='contractDateStartEffectivityDate'
                                                            name='contractDateStartEffectivityDate'
                                                            minDate={this.state.effectivityMinDate}
                                                            value={this.state.contractDateStartEffectivityDate}
                                                            selected={this.state.contractDateStartEffectivityDate}
                                                            dateFormat={"MM/dd/yyyy"}
                                                            className="form-control"
                                                            placeholder="Effectivity Date"
                                                            
                                                        />
                                                    </Col>
                                                    <Form.Label column sm="1" style={{fontWeight : "bold"}}>
                                                    CONTRACT END
                                                    </Form.Label>
                                                    <Col sm="2">
                                                        <DatePicker
                                                            id='contractEnd'
                                                            name='contractEnd'
                                                            onChange={this.handleChangedDate('contractEnd')}
                                                            minDate={this.minDate}
                                                            value={this.state.contractEnd}
                                                            selected={this.state.contractEnd}
                                                            dateFormat={"MM/dd/yyyy"}
                                                            className="form-control"
                                                            autoComplete="off"
                                                            disabled={this.state.disableLibrarian}
                                                        />
                                                    </Col>
                                                    <Form.Label column sm="1" style={{fontWeight : "bold"}}>
                                                    EFFECTIVITY
                                                    </Form.Label>
                                                    <Col sm="2">
                                                        <DatePicker
                                                            id='contractDateEndEffectivityDate'
                                                            name='contractDateEndEffectivityDate'
                                                            onChange={this.handleChangedDate('contractDateEndEffectivityDate')}
                                                            minDate={this.state.effectivityMinDate}
                                                            value={this.state.contractDateEndEffectivityDate}
                                                            selected={this.state.contractDateEndEffectivityDate}
                                                            dateFormat={"MM/dd/yyyy"}
                                                            className="form-control"
                                                            placeholder="Effectivity Date"
                                                            autoComplete="off"
                                                            disabled={this.state.disableLibrarian}
                                                        />
                                                    </Col>
                                                </Form.Group>
                                                <Form.Group as={Row} controlId="formPlaintextEmail">
                                                    <Form.Label column sm="1" style={{fontWeight : "bold"}} className={this.state.hasViewPayCardTypeAccess ? 'display-block' : 'display-none'}>
                                                    PAY CARD TYPE
                                                    </Form.Label>
                                                    <Col sm="2" className={this.state.hasViewPayCardTypeAccess ? 'display-block' : 'display-none'}>
                                                        <Typeahead
                                                            labelKey='name'
                                                            id='cardtypeId'
                                                            name='cardtypeId'
                                                            onChange={this.handleChangedPayCardType}
                                                            options={this.state.cardtypeDDL}
                                                            selected={[this.state.cardtype]}
                                                            disabled={this.state.hasEditPayCardTypeAccess}
                                                        />
                                                    </Col>
                                                    <Form.Label column sm="1" style={{fontWeight : "bold"}} className={this.state.hasViewPayCardTypeAccess ? 'display-block' : 'display-none'}>
                                                    EFFECTIVITY
                                                    </Form.Label>
                                                    <Col sm="2" className={this.state.hasViewPayCardTypeAccess ? 'display-block' : 'display-none'}>
                                                        <DatePicker
                                                            id='payCardTypeEffectivityDate'
                                                            name='payCardTypeEffectivityDate'
                                                            onChange={this.handleChangedDate('payCardTypeEffectivityDate')}
                                                            minDate={this.state.effectivityMinDate}
                                                            value={this.state.payCardTypeEffectivityDate}
                                                            selected={this.state.payCardTypeEffectivityDate}
                                                            dateFormat={"MM/dd/yyyy"}
                                                            className="form-control"
                                                            placeholder="Effectivity Date"
                                                            autoComplete="off"
                                                            disabled={this.state.hasEditPayCardTypeAccess}
                                                        />
                                                    </Col>
                                                    <Form.Label column sm="1" style={{fontWeight : "bold"}} className={this.state.hasViewPayCardNumberAccess ? 'display-block' : 'display-none'}>
                                                    PAY CARD NUMBER
                                                    </Form.Label>
                                                    <Col sm="2" className={this.state.hasViewPayCardNumberAccess ? 'display-block' : 'display-none'}>
                                                        <Form.Control 
                                                            type="text" 
                                                            id="cardNumber"
                                                            name="cardNumber"
                                                            onChange={this.handleChanged}
                                                            value={this.state.cardNumber}
                                                            autoComplete="off" 
                                                            disabled
                                                        />
                                                    </Col>
                                                    <Form.Label column sm="1" style={{fontWeight : "bold"}} className={this.state.hasViewPayCardNumberAccess ? 'display-block' : 'display-none'}>
                                                    EFFECTIVITY
                                                    </Form.Label>
                                                    <Col sm="2" className={this.state.hasViewPayCardNumberAccess ? 'display-block' : 'display-none'}>
                                                        <DatePicker
                                                            id='payCardNumberEffectivityDate'
                                                            name='payCardNumberEffectivityDate'
                                                            onChange={this.handleChangedDate('payCardNumberEffectivityDate')}
                                                            minDate={this.state.effectivityMinDate}
                                                            value={this.state.payCardNumberEffectivityDate}
                                                            selected={this.state.payCardNumberEffectivityDate}
                                                            dateFormat={"MM/dd/yyyy"}
                                                            className="form-control"
                                                            placeholder="Effectivity Date"
                                                            autoComplete="off"
                                                            disabled
                                                        />
                                                    </Col>
                                                </Form.Group>
                                                <Form.Group as={Row} controlId="formPlaintextEmail">
                                                    <Form.Label column sm="1" style={{fontWeight : "bold"}} className={this.state.hasViewPayCardTypeAccess ? 'display-block' : 'display-none'}>
                                                  
                                                    </Form.Label>
                                                    <Col sm="2" className={this.state.hasViewPayCardTypeAccess ? 'display-block' : 'display-none'}>
                                                        {/* <Typeahead
                                                            labelKey='name'
                                                            id='cardtypeId'
                                                            name='cardtypeId'
                                                            onChange={this.handleChangedPayCardType}
                                                            options={this.state.cardtypeDDL}
                                                            selected={[this.state.cardtype]}
                                                            disabled={this.state.hasEditPayCardTypeAccess}
                                                        /> */}
                                                    </Col>
                                                    <Form.Label column sm="1" style={{fontWeight : "bold"}} className={this.state.hasViewPayCardTypeAccess ? 'display-block' : 'display-none'}>
                                                   
                                                    </Form.Label>
                                                    <Col sm="2" className={this.state.hasViewPayCardTypeAccess ? 'display-block' : 'display-none'}>
                                                        {/* <DatePicker
                                                            id='payCardTypeEffectivityDate'
                                                            name='payCardTypeEffectivityDate'
                                                            onChange={this.handleChangedDate('payCardTypeEffectivityDate')}
                                                            minDate={this.state.effectivityMinDate}
                                                            value={this.state.payCardTypeEffectivityDate}
                                                            selected={this.state.payCardTypeEffectivityDate}
                                                            dateFormat={"MM/dd/yyyy"}
                                                            className="form-control"
                                                            placeholder="Effectivity Date"
                                                            autoComplete="off"
                                                            disabled={this.state.hasEditPayCardTypeAccess}
                                                        /> */}
                                                    </Col>
                                                    <Form.Label column sm="1" style={{fontWeight : "bold"}} className={this.state.hasViewPayCardNumberAccess ? 'display-block' : 'display-none'}>
                                                    ATM CARD NUMBER
                                                    </Form.Label>
                                                    <Col sm="2" className={this.state.hasViewPayCardNumberAccess ? 'display-block' : 'display-none'}>
                                                        <Form.Control 
                                                            type="text" 
                                                            id="atmCardNumber"
                                                            name="atmCardNumber"
                                                            onChange={this.handleChangedatmCardNumber}
                                                            value={this.state.atmCardNumber}
                                                            selected={[this.state.atmCardNumber]}
                                                            autoComplete="off" 
                                                            disabled={this.state.disableatmCardNumber}
                                                            maxLength={16}
                                                        />
                                                    </Col>
                                                    <Form.Label column sm="1" style={{fontWeight : "bold"}} className={this.state.hasViewPayCardNumberAccess ? 'display-block' : 'display-none'}>
                                                   
                                                    </Form.Label>
                                                    <Col sm="2" className={this.state.hasViewPayCardNumberAccess ? 'display-block' : 'display-none'}>
                                                        {/* <DatePicker
                                                            id='payCardNumberEffectivityDate'
                                                            name='payCardNumberEffectivityDate'
                                                            onChange={this.handleChangedDate('payCardNumberEffectivityDate')}
                                                            minDate={this.state.effectivityMinDate}
                                                            value={this.state.payCardNumberEffectivityDate}
                                                            selected={this.state.payCardNumberEffectivityDate}
                                                            dateFormat={"MM/dd/yyyy"}
                                                            className="form-control"
                                                            placeholder="Effectivity Date"
                                                            autoComplete="off"
                                                            disabled
                                                        /> */}
                                                    </Col>
                                                </Form.Group>
                                                <Form.Group as={Row} controlId="formPlaintextEmail">
                                                    <Form.Label column sm="1" style={{fontWeight : "bold"}} className={this.state.hasViewPayCardTypeAccess ? 'display-block' : 'display-none'}>
                                                    PAY CARD STATUS
                                                    </Form.Label>
                                                    <Col sm="2" className={this.state.hasViewPayCardTypeAccess ? 'display-block' : 'display-none'}>
                                                        <Typeahead
                                                            labelKey='name'
                                                            id='paycardstatusId'
                                                            name='paycardstatusId'
                                                            onChange={this.handleChangedPayCardStatus}
                                                            options={this.state.paycardStatusDDL}
                                                            selected={[this.state.paycardStatus]}
                                                            disabled={this.state.hasEditPayCardTypeAccess}
                                                        />
                                                    </Col>
                                                </Form.Group>
                                                <Form.Group as={Row} controlId="formPlaintextEmail">
                                                    <Form.Label column sm="1" style={{fontWeight : "bold"}}>
                                                    PAY TYPE
                                                    </Form.Label>
                                                    <Col sm="2">
                                                        <Typeahead
                                                            labelKey='name'
                                                            id='paytypeId'
                                                            name='paytypeId'
                                                            onChange={this.handleChangedPayType}
                                                            options={this.state.paytypeDDL}
                                                            selected={[this.state.paytype]}
                                                            disabled={this.state.disableLibrarian}
                                                        />
                                                    </Col>
                                                    <Form.Label column sm="1" style={{fontWeight : "bold"}}>
                                                    EFFECTIVITY
                                                    </Form.Label>
                                                    <Col sm="2">
                                                        <DatePicker
                                                            id='payTypeEffectivityDate'
                                                            name='payTypeEffectivityDate'
                                                            onChange={this.handleChangedDate('payTypeEffectivityDate')}
                                                            minDate={this.state.effectivityMinDate}
                                                            value={this.state.payTypeEffectivityDate}
                                                            selected={this.state.payTypeEffectivityDate}
                                                            dateFormat={"MM/dd/yyyy"}
                                                            className="form-control"
                                                            placeholder="Effectivity Date"
                                                            autoComplete="off"
                                                            disabled={this.state.disableLibrarian}
                                                        />
                                                    </Col>
                                                    <Form.Label column sm="1" style={{fontWeight : "bold"}}>
                                                    WORK SCHEDULE
                                                    </Form.Label>
                                                    <Col sm="2">
                                                        <Typeahead
                                                            labelKey='description'
                                                            id='scheduleId'
                                                            name='scheduleId'
                                                            onChange={this.handleChangedSchedule}
                                                            options={this.state.scheduleDDL}
                                                            selected={[this.state.schedule]}
                                                            disabled={this.state.disableLibrarian}
                                                        />
                                                    </Col>
                                                    {/* <Form.Label column sm="1" style={{fontWeight : "bold"}}>
                                                    EFFECTIVITY
                                                    </Form.Label>
                                                    <Col sm="2">
                                                        <DatePicker
                                                            id='workScheduleEffectivityDate'
                                                            name='workScheduleEffectivityDate'
                                                            onChange={this.handleChangedDate('workScheduleEffectivityDate')}
                                                            minDate={this.state.effectivityMinDate}
                                                            value={this.state.workScheduleEffectivityDate}
                                                            selected={this.state.workScheduleEffectivityDate}
                                                            dateFormat={"MM/dd/yyyy"}
                                                            className="form-control"
                                                            placeholder="Effectivity Date"
                                                            autoComplete="off"
                                                        />
                                                    </Col> */}
                                                    
                                                </Form.Group>
                                                <Form.Group as={Row} controlId="formPlaintextEmail">
                                                <Form.Label column sm="1" style={{fontWeight : "bold"}}>
                                                    PAY MODE
                                                    </Form.Label>
                                                    <Col sm="2">
                                                        <Typeahead
                                                            labelKey='name'
                                                            id='paymodeId'
                                                            name='paymodeId'
                                                            onChange={this.handleChangedPayMode}
                                                            options={this.state.paymodeDDL}
                                                            selected={[this.state.paymode]}
                                                            disabled={this.state.disableLibrarian}
                                                        />
                                                    </Col>
                                                    <Form.Label column sm="1" style={{fontWeight : "bold"}}>
                                                    EFFECTIVITY
                                                    </Form.Label>
                                                    <Col sm="2">
                                                        <DatePicker
                                                            id='payModeEffectivityDate'
                                                            name='payModeEffectivityDate'
                                                            onChange={this.handleChangedDate('payModeEffectivityDate')}
                                                            minDate={this.state.effectivityMinDate}
                                                            value={this.state.payModeEffectivityDate}
                                                            selected={this.state.payModeEffectivityDate}
                                                            dateFormat={"MM/dd/yyyy"}
                                                            className="form-control"
                                                            placeholder="Effectivity Date"
                                                            autoComplete="off"
                                                            disabled={this.state.disableLibrarian}
                                                        />
                                                    </Col>
                                                    <Form.Label column sm="1" style={{fontWeight : "bold"}} className={this.state.hasViewSalaryAccess ? 'display-block' : 'display-none'}>
                                                    SALARY RATE
                                                    </Form.Label>
                                                    <Col sm="2" className={this.state.hasViewSalaryAccess ? 'display-block' : 'display-none'}>
                                                        <Form.Control 
                                                            type="text"
                                                            id="salaryRate"
                                                            name="salaryRate"
                                                            value={this.state.salaryRate}
                                                            onChange={this.handleChanged}
                                                            onKeyPress={this.IsDecimal.bind(this)}
                                                            maxLength="13"
                                                            autoComplete="off"
                                                            disabled={this.state.hasEditSalaryAccess}
                                                        /> 
                                                    </Col>
                                                    <Form.Label column sm="1" style={{fontWeight : "bold"}} className={this.state.hasViewSalaryAccess ? 'display-block' : 'display-none'}>
                                                    EFFECTIVITY
                                                    </Form.Label>
                                                    <Col sm="2" className={this.state.hasViewSalaryAccess ? 'display-block' : 'display-none'}>
                                                        <DatePicker
                                                            id='salaryRateEffectivityDate'
                                                            name='salaryRateEffectivityDate'
                                                            onChange={this.handleChangedDate('salaryRateEffectivityDate')}
                                                            minDate={this.state.effectivityMinDate}
                                                            value={this.state.salaryRateEffectivityDate}
                                                            selected={this.state.salaryRateEffectivityDate}
                                                            dateFormat={"MM/dd/yyyy"}
                                                            className="form-control"
                                                            placeholder="Effectivity Date"
                                                            autoComplete="off"
                                                            //disabled={this.state.disableLibrarian}
                                                            disabled={this.state.hasEditSalaryAccess}
                                                        />
                                                    </Col>
                                                </Form.Group>
                                                <Form.Group as={Row} controlId="formPlaintextEmail">
                                                <Form.Label column sm="1" style={{fontWeight : "bold"}}>
                                                    PERIOD TYPE
                                                    </Form.Label>
                                                    <Col sm="2">
                                                        <Typeahead
                                                            labelKey='name'
                                                            id='periodtypeId'
                                                            name='periodtypeId'
                                                            onChange={this.handleChangedPeriodType}
                                                            //options={range(0, 10).map((o) => `this.state.periodtypeDDL ${o}`)}
                                                            options={this.state.periodtypeDDL}
                                                            selected={[this.state.periodtype]}
                                                            paginate={true}
                                                            disabled={this.state.disableLibrarian}
                                                        />
                                                    </Col>
                                                    <Col sm="3"></Col>
                                                    {/* <Form.Label column sm="1" style={{fontWeight : "bold"}}>
                                                    EFFECTIVITY
                                                    </Form.Label>
                                                    <Col sm="2">
                                                        <DatePicker
                                                            id='periodTypeEffectivityDate'
                                                            name='periodTypeEffectivityDate'
                                                            onChange={this.handleChangedDate('periodTypeEffectivityDate')}
                                                            minDate={this.state.effectivityMinDate}
                                                            value={this.state.periodTypeEffectivityDate}
                                                            selected={this.state.periodTypeEffectivityDate}
                                                            dateFormat={"MM/dd/yyyy"}
                                                            className="form-control"
                                                            placeholder="Effectivity Date"
                                                            autoComplete="off"
                                                        />
                                                    </Col> */}
                                                    <Form.Label column sm="1" style={{fontWeight : "bold"}}>
                                                    SEA RATE
                                                    </Form.Label>
                                                    <Col sm="2">
                                                        <Form.Control 
                                                            type="text"
                                                            id="seaRate"
                                                            name="seaRate"
                                                            value={this.state.seaRate}
                                                            onChange={this.handleChanged}
                                                            onKeyPress={this.IsDecimal.bind(this)}
                                                            maxLength="13"
                                                            autoComplete="off"
                                                            disabled={this.state.disableLibrarian}
                                                        />
                                                    </Col>
                                                    <Form.Label column sm="1" style={{fontWeight : "bold"}}>
                                                    EFFECTIVITY
                                                    </Form.Label>
                                                    <Col sm="2">
                                                        <DatePicker
                                                            id='seaRateEffectivityDate'
                                                            name='seaRateEffectivityDate'
                                                            onChange={this.handleChangedDate('seaRateEffectivityDate')}
                                                            minDate={this.state.effectivityMinDate}
                                                            value={this.state.seaRateEffectivityDate}
                                                            selected={this.state.seaRateEffectivityDate}
                                                            dateFormat={"MM/dd/yyyy"}
                                                            className="form-control"
                                                            placeholder="Effectivity Date"
                                                            autoComplete="off"
                                                            disabled={this.state.disableLibrarian}
                                                        />
                                                    </Col>
                                                </Form.Group>
                                                <Form.Group as={Row} controlId="formPlaintextEmail">
                                                <Form.Label column sm="1" style={{fontWeight : "bold"}}>
                                                    APPROVER 1
                                                    </Form.Label>
                                                    <Col sm="2">
                                                        <Typeahead 
                                                            labelKey='employeeName'
                                                            id='approver1Id'
                                                            name='approver1Id'
                                                            onChange={this.handleChangedApprover1}
                                                            options={this.state.approver1DDL}
                                                            selected={[this.state.approver1]}
                                                            disabled={this.state.disableLibrarian}
                                                        />
                                                    </Col>
                                                    <Col sm="3"></Col>
                                                    {/* <Form.Label column sm="1" style={{fontWeight : "bold"}}>
                                                    EFFECTIVITY
                                                    </Form.Label>
                                                    <Col sm="2">
                                                        <DatePicker
                                                            id='approver1EffectivityDate'
                                                            name='approver1EffectivityDate'
                                                            onChange={this.handleChangedDate('approver1EffectivityDate')}
                                                            minDate={this.state.effectivityMinDate}
                                                            value={this.state.approver1EffectivityDate}
                                                            selected={this.state.approver1EffectivityDate}
                                                            dateFormat={"MM/dd/yyyy"}
                                                            className="form-control"
                                                            placeholder="Effectivity Date"
                                                            autoComplete="off"
                                                        />
                                                    </Col> */}
                                                    <Form.Label column sm="1" style={{fontWeight : "bold"}}>
                                                    COLA RATE
                                                    </Form.Label>
                                                    <Col sm="2">
                                                        <Form.Control 
                                                            type="text"
                                                            id="colaRate"
                                                            name="colaRate"
                                                            value={this.state.colaRate}
                                                            onChange={this.handleChanged}
                                                            onKeyPress={this.IsDecimal.bind(this)}
                                                            maxLength="13"
                                                            autoComplete="off"
                                                            disabled={this.state.disableLibrarian}
                                                        />
                                                    </Col>
                                                    <Form.Label column sm="1" style={{fontWeight : "bold"}}>
                                                    EFFECTIVITY
                                                    </Form.Label>
                                                    <Col sm="2">
                                                        <DatePicker
                                                            id='colaRateEffectivityDate'
                                                            name='colaRateEffectivityDate'
                                                            onChange={this.handleChangedDate('colaRateEffectivityDate')}
                                                            minDate={this.state.effectivityMinDate}
                                                            value={this.state.colaRateEffectivityDate}
                                                            selected={this.state.colaRateEffectivityDate}
                                                            dateFormat={"MM/dd/yyyy"}
                                                            className="form-control"
                                                            placeholder="Effectivity Date"
                                                            autoComplete="off"
                                                            disabled={this.state.disableLibrarian}
                                                        />
                                                    </Col>
                                                </Form.Group>
                                                <Form.Group as={Row} controlId="formPlaintextEmail">
                                                <Form.Label column sm="1" style={{fontWeight : "bold"}}>
                                                    APPROVER 2
                                                    </Form.Label>
                                                    <Col sm="2">
                                                        <Typeahead 
                                                            labelKey='employeeName'
                                                            id='approver2Id'
                                                            name='approver2Id'
                                                            onChange={this.handleChangedApprover2}
                                                            options={this.state.approver2DDL}
                                                            selected={[this.state.approver2]}
                                                            disabled={this.state.disableLibrarian}
                                                        />
                                                    </Col>
                                                    <Col sm="3"></Col>
                                                    {/* <Form.Label column sm="1" style={{fontWeight : "bold"}}>
                                                    EFFECTIVITY
                                                    </Form.Label>
                                                    <Col sm="2">
                                                        <DatePicker
                                                            id='approver2EffectivityDate'
                                                            name='approver2EffectivityDate'
                                                            onChange={this.handleChangedDate('approver2EffectivityDate')}
                                                            minDate={this.state.effectivityMinDate}
                                                            value={this.state.approver2EffectivityDate}
                                                            selected={this.state.approver2EffectivityDate}
                                                            dateFormat={"MM/dd/yyyy"}
                                                            className="form-control"
                                                            placeholder="Effectivity Date"
                                                            autoComplete="off"
                                                        />
                                                    </Col> */}
                                                    <Form.Label column sm="1" style={{fontWeight : "bold"}}>
                                                    ECOLA RATE
                                                    </Form.Label>
                                                    <Col sm="2">
                                                        <Form.Control 
                                                            type="text"
                                                            id="ecolaRate"
                                                            name="ecolaRate"
                                                            value={this.state.ecolaRate}
                                                            onChange={this.handleChanged}
                                                            onKeyPress={this.IsDecimal.bind(this)}
                                                            maxLength="13"
                                                            autoComplete="off"
                                                            disabled={this.state.disableLibrarian}
                                                        /> 
                                                    </Col>
                                                    <Form.Label column sm="1" style={{fontWeight : "bold"}}>
                                                    EFFECTIVITY
                                                    </Form.Label>
                                                    <Col sm="2">
                                                        <DatePicker
                                                            id='eColaRateEffectivityDate'
                                                            name='eColaRateEffectivityDate'
                                                            onChange={this.handleChangedDate('eColaRateEffectivityDate')}
                                                            minDate={this.state.effectivityMinDate}
                                                            value={this.state.eColaRateEffectivityDate}
                                                            selected={this.state.eColaRateEffectivityDate}
                                                            dateFormat={"MM/dd/yyyy"}
                                                            className="form-control"
                                                            placeholder="Effectivity Date"
                                                            autoComplete="off"
                                                            disabled={this.state.disableLibrarian}
                                                        />
                                                    </Col>
                                                </Form.Group>
                                                <Form.Group as={Row} controlId="formPlaintextEmail">
                                                    <Form.Label column sm="1" style={{fontWeight : "bold"}}>
                                                    REMARKS
                                                    </Form.Label>
                                                    <Col sm="11">
                                                        <Form.Control 
                                                            type="text"
                                                            id="remarks"
                                                            name="remarks"
                                                            value={this.state.remarks}
                                                            onChange={this.handleChanged} 
                                                            autoComplete="off"
                                                            disabled={this.state.disableLibrarian}
                                                        />
                                                    </Col>
                                                </Form.Group>
                                            </Form>
                                        </Card.Body>
                                        </Accordion.Collapse>
                                    </Card>
                                    <Card>
                                            <div className="card-header-tab">
                                                <Accordion.Toggle as={Button} variant="link" eventKey="3" style={{color: "#FFFFFF"}}>
                                                        EMPLOYMENT HISTORY
                                                </Accordion.Toggle>
                                            </div>
                                            <Accordion.Collapse eventKey="3">
                                                <div>
                                                    <BootstrapTable
                                                        striped
                                                        hover
                                                        condensed
                                                        keyField = "id"
                                                        data = { this.state.employmentGRID }
                                                        columns = { employmentCol}
                                                        selectRow = { selectrowEmployment }
                                                        cellEdit = { cellEditFactory({ 
                                                            mode: 'dbclick', 
                                                            blurToSave: true,
                                                            afterSaveCell: (oldValue, newValue, row, column) => { 
                                                                //this.GridDataModified(oldValue, newValue, row.id, column.dataField)
                                                            }})
                                                        }
                                                        rowClasses="noser-table-row-class no-checkbox"
                                                        noDataIndication={ () => <div style={{width:'100%'}}>No record found.</div> }
                                                    />
                                                </div>
                                            </Accordion.Collapse>
                                        </Card>
                                    </Accordion>
                                </Tab>
                                {/* <Tab eventKey="librarian" title="Librarian">
                                    <Accordion className="mt-2"  defaultActiveKey="0">
                                        <Card className="card-tab">
                                        <div className="card-header-tab">
                                            <Accordion.Toggle as={Button} variant="link" eventKey="0" style={{color: "#FFFFFF"}}></Accordion.Toggle>
                                        </div>
                                        <Card.Body style={{height:'280px'}}>
                                            <Form>
                                                <Form.Group as={Row} controlId="formPlaintextEmail">
                                                    <Form.Label column sm="2" style={{fontWeight : "bold"}}>
                                                    CABINET
                                                    </Form.Label>
                                                    <Col sm="3">
                                                        <Typeahead
                                                            labelKey='name'
                                                            id='cabinetId'
                                                            name='cabinetId'
                                                            onChange={this.handleChangedCabinet}
                                                            options={this.state.cabinetDDL}
                                                            selected={[this.state.cabinet]}
                                                            disabled={this.state.disableLibrarian ? false : true}
                                                        />
                                                    </Col>
                                                    <Col sm="2">
                                                    </Col>
                                                    <Form.Label column sm="2" style={{fontWeight : "bold"}}>
                                                    DRAWER
                                                    </Form.Label>
                                                    <Col sm="3">
                                                        <Typeahead
                                                            labelKey='name'
                                                            id='drawerId'
                                                            name='drawerId'
                                                            onChange={this.handleChangedDrawer}
                                                            options={this.state.drawerDDL}
                                                            selected={[this.state.drawer]}
                                                            disabled={this.state.disableLibrarian ? false : true}
                                                        />
                                                    </Col>
                                                </Form.Group>
                                                <Form.Group as={Row} controlId="formPlaintextEmail">
                                                    <Form.Label column sm="1" style={{fontWeight : "bold"}}>
                                                    REQUEST DATE
                                                    </Form.Label>
                                                    <Col sm="3">
                                                        <DatePicker
                                                            id='requestDate'
                                                            name='requestDate'
                                                            onChange={this.handleChangedDate('requestDate')}
                                                            minDate={this.state.effectivityMinDate}
                                                            value={this.state.requestDate}
                                                            selected={this.state.requestDate}
                                                            dateFormat={"MM/dd/yyyy"}
                                                            className="form-control"
                                                            placeholder="Request Date"
                                                            autoComplete="off"
                                                            disabled={this.state.disableLibrarian ? false : true}
                                                        />
                                                    </Col>
                                                    <Form.Label column sm="1" style={{fontWeight : "bold"}}>
                                                    PULL OUT DATE
                                                    </Form.Label>
                                                    <Col sm="3">
                                                        <DatePicker
                                                            id='pullOutDate'
                                                            name='pullOutDate'
                                                            onChange={this.handleChangedDate('pullOutDate')}
                                                            minDate={this.state.effectivityMinDate}
                                                            value={this.state.pullOutDate}
                                                            selected={this.state.pullOutDate}
                                                            dateFormat={"MM/dd/yyyy"}
                                                            className="form-control"
                                                            placeholder="Pull Out Date"
                                                            autoComplete="off"
                                                            disabled={this.state.disableLibrarian ? false : true}
                                                        />
                                                    </Col>
                                                    <Form.Label column sm="1" style={{fontWeight : "bold"}}>
                                                    RETURN DATE
                                                    </Form.Label>
                                                    <Col sm="3">
                                                        <DatePicker
                                                            id='returnDate'
                                                            name='returnDate'
                                                            onChange={this.handleChangedDate('returnDate')}
                                                            minDate={this.state.effectivityMinDate}
                                                            value={this.state.returnDate}
                                                            selected={this.state.returnDate}
                                                            dateFormat={"MM/dd/yyyy"}
                                                            className="form-control"
                                                            placeholder="Return Date"
                                                            autoComplete="off"
                                                            disabled={this.state.disableLibrarian ? false : true}
                                                        />
                                                    </Col>
                                                </Form.Group>
                                            </Form>
                                        </Card.Body>
                                        </Card>
                                    </Accordion>
                                </Tab> */}
                            </Tabs>
                        </Card.Body>
                        <ButtonToolbar mt="5" style={{marginRight:'13px',marginBottom:'10px'}}>
                            <Button disabled={this.state.disablebutton}  className="ml-auto noser-button-mr1" variant="success" onClick = {this.handleSubmitClick}>Submit</Button>
                            <Button  href="/manageemployees" className="noser-button" variant="warning">Back</Button>
                        </ButtonToolbar>
                    </Card>
                </Container>
                <NoserLoading show={this.state.isloading} />
            </div>
        )
    }

}
export default ManageEmployee;
